@import '../../../retailer/next/styles/variables.scss';

.next-retailer .offer-form-webstore,
.next .offer-form-webstore {
  .image-upload {
    margin-bottom: $margin-half;
    img {
      padding: 15px;
      max-width: 100%;
    }
  }
  .form-section.first {
    display: flex;
    @media screen and (max-width: $breakpoint-desktop) {
      display: block;
    }
    .div + div {
      flex-grow: 1;
    }
  }
  .select-type {
    margin-left: $margin-default;
    @media screen and (max-width: $breakpoint-desktop) {
      margin-left: 0;
    }
  }
}
