@import '../../styles/editor';
@import '../../styles/colors';
@import '../../styles/variables';

.next .pricing-item {
  flex: 1 1 auto;
  button {
    display: flex;
    border: none;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    margin-bottom: 16px;
    font-size: 1rem;

    span,
    img {
      display: inline-block;
    }

    span + svg,
    img {
      margin-left: 16px;
    }
  }

  .component {
    .title {
      display: flex;
      align-items: center;

      .input-field {
        flex: 1;
      }

      .actions {
        button {
          margin-left: $sidebar-page-horizontal-padding;
          margin-bottom: 0;
        }
      }
    }
  }

  .subheader {
    display: flex;
    justify-content: space-between;

    > .actions {
      display: flex;

      button {
        margin-bottom: 0;
      }
    }
  }

  .step {
    display: flex;
    align-items: center;

    .step--from {
      flex: 1;
    }

    .step--price {
      flex: 1;
      padding: 0 $sidebar-page-horizontal-padding;
    }

    .step--actions {
      button {
        margin-bottom: 0;

        img {
          margin-left: 0;
        }
      }
    }
  }

  .toggle-button {
    margin-bottom: 0;
  }
}
