.next-retailer {
  h1,
  h2,
  h3,
  p:not(:last-child) {
    margin-bottom: $margin-half;
  }

  h2 {
    font-size: $font-size-xxl;
  }

  p svg {
    position: relative;
    top: 0.3em;
  }

  a {
    display: inline;
  }

  /* Utility classes */
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-error {
    color: $color-rouge;
  }
  .text-small {
    font-size: $font-size-s;
  }
}
