@import '../../../retailer/next/styles/variables.scss';

$size-product-image-small: 50px;

.next-retailer .product-select,
.next-retailer .stampcard-product-picker,
.next .product-select {
  .product-search-field {
    margin-bottom: $margin-half;
  }

  .search-result {
    cursor: pointer;

    &.disabled {
      cursor: initial;

      > * {
        opacity: 0.5;
      }

      .image-container img {
        opacity: 0.5;
      }

      .title {
        color: $color-light;
        justify-content: left;
        .filtered-by-unit {
          color: red;
          padding-left: $margin-half;
          font-size: $font-size-s;
        }
      }
    }
  }

  .product-search-results {
    margin-top: $margin-mini;
    margin-bottom: $margin-double;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;

    &:empty {
      display: none;
    }

    .result-title {
      color: $color-light;
      font-size: $font-size-s;
      font-family: $font-default-medium;
      text-transform: uppercase;
      letter-spacing: -0.2px;
      cursor: initial;
    }

    .show-more {
      padding: $margin-half;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-k-orange;
      font-family: $font-default-bold;
      cursor: pointer;

      * {
        pointer-events: none;
      }

      img {
        margin-left: $margin-half;
        height: 11px;
        width: 11px;
      }
    }

    .no-results {
      padding: $margin-half;
      text-align: center;

      + .add-own-result {
        border-top: $border-divider;
      }
    }

    .loading-results {
      padding: $margin-half;
      display: flex;
      align-items: center;
      position: relative;
    }

    .show-more,
    .loading-results {
      + .search-result {
        border-top: 3px solid $color-divider;
      }
    }

    .add-own-result {
      cursor: initial;
      .result-details {
        margin-top: 11px;
        display: flex;
        flex-direction: row;
        .image-container img {
          height: 23px;
          width: 23px;
        }
      }
    }
  }
}
