// Color usage
$color-font: $color-dark-grey;
$color-font-emph: $color-k-orange;
$color-slight-emph: $color-dark-blue;
$color-label: $color-grey;
$color-light: $color-cool-grey;
$color-logo: $color-k-orange;
$color-logo-light: $color-white;
$color-font-inverse: $color-white;
$color-selected: $color-k-orange;
$color-active: $color-k-orange-2;
$color-button-inverse: $color-dark-blue;
$color-button-disabled: $color-grey;
$color-select-disabled: $color-cool-grey;
$color-divider: rgba($color-cool-grey, 0.29);
$color-error: $color-rouge;
$color-positive: $color-green;
$color-negative: $color-rouge;
$color-navigation-link-disabled: $color-cool-grey;
$color-draft: $color-grape-purple;

$background-button-default: $color-k-orange;
$background-button-inverse: $color-white;
$background-button-disabled: $color-light-grey;
$background-button-secondary: $color-dark-blue;
$background-select: $color-white;
$background-navigation: $color-dark-blue;
$background-header: $color-white;
$background-lander: $color-dark-blue;
$background-content: $color-light-blue;
$background-box: $color-white;
$background-box-good: $color-dark-blue;
$background-box-ok: $color-dark-grey;
$background-box-bad: $color-grape-purple;
$background-box-participation: $color-dark-blue;
$background-box-participation-dark: $color-darkest-blue;
$background-box-campaign: $color-dark-blue;
$background-box-draft: $color-draft;
$background-box-dark: $color-dark-blue;
$background-selected: rgba($color-k-orange-2, 0.05);
$background-slider-button: $color-white;
$background-offer-select: rgba($color-cool-grey, 0.04);
$background-bubble: $color-dark-blue;
$background-bubble-inverse: $color-white;
$background-numbers-header: $color-darkest-blue;
$background-tag-participating: $color-green;
$background-tag-recommended: $color-k-orange-2;
$background-tag-new: $color-k-orange;
$background-tag-draft: $color-draft;
$background-tag-passed: $color-cool-grey;
$background-tag-type: $color-dark-blue;
$background-status-bubble: $color-white;
$background-status-bubble-passed: $color-k-orange;
$background-status-bubble-ongoing: repeating-linear-gradient(
  -45deg,
  $color-green,
  $color-green 6px,
  $color-transparent 6px,
  $color-transparent 7px
);
$background-status-bubble-ongoing-fallback: $color-green;
$background-status-bubble-hover: rgba($color-white, 0.625);
$background-status-bubble-disabled: $color-grey;
$background-table: $color-white;
$background-info-triangle: $color-white;
$background-offer-info-box: $color-light-orange;
$background-navigate-arrow-button: $color-lighter-blue;
$background-overlay: rgba($color-dark-grey, 0.4);
$background-not-selected: $color-grey;
$background-offer-popup: $color-k-orange;
$background-banner: $color-dark-blue;
$background-tab-active: $color-k-orange-2;
$background-show-more-overlay: linear-gradient(0deg, #ffffff 0%, $color-transparent 40%);
$background-overlay-box: $color-lightest-grey;
$background-radio-button: $color-white;
$background-tabs: $color-dark-blue;
$background-tabs-active: $color-white;
$background-input-disabled: $color-lightest-grey;
$background-delete: $color-dark-grey;
$background-promotion-editor: rgba($color-k-orange-2, 0.11);
$background-table-hover: $color-lightest-grey;
$background-preview: $color-middle-grey;

$box-border-width: 3px;
$border-navigation: 1px solid $color-blue;
$border-select: 1px solid $color-cool-grey;
$border-button-default: 2px solid $color-k-orange;
$border-button-inverse: 2px solid $color-dark-blue;
$border-button-disabled: 2px solid $color-light-grey;
$border-selected: 2px solid $color-k-orange;
$border-not-selected: 2px solid $color-dark-blue;
$border-preview: 2px solid $color-dark-blue;
$border-file-upload: 2px dashed $color-cool-grey;
$border-divider: 1px solid $color-divider;
$border-selected-radio-button: 5px solid $color-k-orange;
$border-input-number: 1px solid $color-dark-blue;
$border-recommended: $box-border-width solid $background-tag-recommended;
$border-participating: $box-border-width solid $background-tag-participating;
$border-draft: $box-border-width solid $color-draft;
$border-divider-bold: 1px solid $color-cool-grey;
$border-editor-template: 2px solid $color-k-orange-2;
$border-editor-field: 2px solid $color-k-orange-2;
$border-sendout-box: 2px solid $color-k-orange;
$border-campaign-thumbnail: 4px solid $color-white;
$border-selected-light: 1px solid $color-k-orange;
$border-promotion-editor: 2px solid $color-dark-blue;
$border-checkbox: 1px solid $color-light-grey;
$border-campaign-preview: 10px solid $color-white;
$border-tab-button: 2px solid $background-tabs-active;

// Graph colors
$background-graph: $color-dark-blue;
$background-x-axis: $color-darkest-blue;
$color-graph-primary: $color-orange;
$color-graph-secondary: $color-green-2;
$color-graph-3: $color-teal;
$color-graph-4: $color-green;
$color-graph-5: $color-rouge;
$color-x-axis: $color-white;
$color-y-axis: $color-grey;
$color-grid-line: $color-light-blue;
$color-graph-selected: $color-k-orange;
$background-graph-selected: rgba($color-k-orange, 0.3);
$color-graph-unselected: $color-teal;
$background-graph-unselected: rgba($color-teal, 0.3);
$background-legend-pattern: repeating-linear-gradient(
  -45deg,
  $color-transparent,
  $color-transparent 3px,
  $color-white 3px,
  $color-white 6px
);

// tables
$table-border-color: $color-cool-grey;
$table-border-width: 1px;
$table-cell-padding: 0.75rem;

$box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
$box-shadow-inverse: 0 -2px 8px 0 rgba(0, 0, 0, 0.07);
$box-shadow-thumbnail: 0 5px 20px 0 rgba(0, 0, 0, 0.5);
$box-shadow-thumbnail-light: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
$box-shadow-slider-handle: 0 0 8px 0 rgba(0, 0, 0, 0.2);
$box-shadow-center: 0 0 4px 0 rgba(0, 0, 0, 0.07);

// Sizes
$width-navigation: 320px;
$width-navigation-mobile: 270px;
$max-width-full-content: 1024px;
$height-channel-preview: 255px;
$min-width-channel-preview: 355px;
$min-width-dynamic-price: 170px;
$basis-header-description: 260px;
$width-input-number: 140px;
$width-status-bubble: 80px;

$width-text-content: 28em;
$width-narrow-content: 300px;
$min-width-navigate-arrow-button: 50px;
$max-width-mobile-preview: 375px;
$basis-channel-select: 310px;
$basis-offer-display: 295px;
$min-width-retailer-menu: 210px;
$max-width-offer-image: 180px;
$max-width-channel-description: 600px;
$width-small-segment-box: 290px;
$width-small-box: 302px;
$basis-box-text: 400px;
$basis-box-steps: 250px;
$height-logo-title-big: 94px;
$max-width-banner-subtitle: 500px;
$height-footer-logo: 45px;
$max-width-banner-title: 960px;
$max-width-content-image: 620px;
$basis-offer-info-element: 150px;
$max-width-order-notifications: 600px;
$width-order-notification-button: 126px;
$min-width-contact-input: 260px;
$basis-result-element: 130px;
$height-range-slider: $margin-default;
$max-width-promotion: 400px;

$size-icon: 20px;
$size-header-image: 40px;
$size-header-icon: 15px;
$size-navigation-icon: 35px;
$size-navigation-icon-mobile: 25px;
$size-segment-icon: 40px;
$size-segment-icon-big: 50px;
$size-info-icon: 20px;
$size-info-icon-mobile: 15px;
$size-slider-button: 2 * $margin-default - $margin-half;
$size-radio-button: 16px;
$size-offer-image-small: 100px;
$size-offer-image-small-mobile: 70px;
$size-status-icon: 40px;
$size-money-icon: 40px;
$size-sendout-offer-image: 80px;
$size-offer-info: 100px;
$size-box-info-button: 60px;
$size-close-button: 40px;
$size-close-button-small: 32px;
$size-sendout-preview-thumb: 235px;
$size-sendout-preview-thumb-mobile: 200px;
$size-zoom-button: 40px;
$size-menu-button: 50px;
$size-arrow-down: 11px;
$size-arrow-down-big: 20px;
$size-loading-icon: 20px;
$size-indicator-icon: 28px;
$size-legend-swatch: 10px;
$size-results-offer-image: 74px;
$size-results-offer-image-mobile: 52px;
$size-table-image: 50px;
$size-button-image: 23px;
$size-remove-image: 20px;
$size-arrow-back: 24px;
$size-arrow-caret: 8px;
$size-target-group: 35px;
$size-target-group-mobile: 25px;
$size-product-image-small: 50px;
$size-step-icon: 40px;
$size-step-icon-mobile: 30px;

$border-radius-button: 2px;
$border-radius-box: 3px;
$border-radius-select: 11px;
$border-radius-radio-button: 9px;
$border-radius-bubble: 50px;
$border-radius-border-box: 11px;
$border-radius-tab: 5px;
$border-radius-campaign-thumbnail: 2px;
$border-radius-delete: 5px;
$border-radius-arrow-button: 3px;

$location-info-button: 8px;
