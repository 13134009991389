@import '../../styles/variables.scss';

.next-retailer .deliveries-page {
  .delivery-lists {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 (-$margin-default);
    .delivery-list {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 280px;
      margin: 0 $margin-default;
      margin-top: $margin-default;
      @media screen and (max-width: $breakpoint-desktop) {
        .delivery-box {
          margin-bottom: $margin-half;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .section-header {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.next-retailer .content-info {
  position: absolute;
  margin: $margin-default;
  bottom: 0;
  color: $color-grey;
  @media screen and (max-width: $breakpoint-desktop) {
    position: relative;
    margin-top: $margin-half;
  }
}
