@import '../../styles/variables.scss';
@import '../../../../../components/next/styles/colors.scss';

.next-retailer .news-item-page {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
    }

    span {
      color: $color-grey;
    }
  }

  .news-item--image {
    margin-top: $margin-default;

    img {
      display: block;
      border-radius: $border-radius;
      max-height: 400px;
    }
  }

  .subtitle {
    margin-top: $margin-default;
  }

  .news-item--content {
    margin-top: $margin-default;

    h1,
    h2,
    h3,
    h4 {
      font-size: $font-size-base;
      font-family: $font-default;
    }
  }
}
