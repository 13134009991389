@import '../../styles/variables';
@import '../../styles/colors';

.next .collectionStampCards {
  overflow: scroll;
  position: relative;
  padding-bottom: 80px;

  table.styled td {
    line-height: initial;
  }
}
