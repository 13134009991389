.table-container {
  display: flex;
  flex-direction: column;

  &__extras {
    display: flex;
    flex: auto;
    flex-flow: column;
  }
}

.arrow {
  &.disabled {
    path {
      fill: gray;
    }
  }
}
.not-selectable {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
