@import '../../styles/colors';
@import '../../styles/variables';

.next .home {
  padding: 16px 24px;
  h2 {
    margin-bottom: 10px;
  }

  .results-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: $default-vertical-margin;
    line-height: 1;

    .result {
      width: 49%;
      background-color: white;
      text-align: center;
      padding: 24px 0;
      border-radius: $border-radius-normal;
      @include block-shadow();

      span {
        display: block;
      }

      &__value {
        font-size: 64px;
        font-family: 'SkattaSans-Bold';
        margin-bottom: 4px;
      }

      &__detail {
        margin-bottom: 12px;
      }
    }
  }
}
