@import '../../styles/colors';

.pricing-group-selector {
  &__wrapper {
    display: block;
    position: relative;
    &--hidden {
      display: none;
    }
  }
  &__list {
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    font-size: 90%;
    position: absolute;
    overflow: auto;
    z-index: 100000;
    display: block;
    left: 0;
    right: 0;
    &--hidden {
      display: none;
    }
  }
  &__pricing-group {
    cursor: pointer;
    padding: 6px 12px;

    &:first-child {
      padding-top: 12px;
    }

    &:last-child {
      padding-bottom: 12px;
    }

    &--highlight {
      background-color: #eee;
    }
  }

  &__selected {
    font-size: medium;
    border: 1px black;
  }

  &__delete {
    margin-left: 8px;
    border: unset;
    background-color: transparent;
    vertical-align: middle;

    &:hover {
      cursor: pointer;
    }
  }

  &__create {
    margin-top: 8px;
    padding: 8px;
    border-radius: 4px;
    background-color: #f8f8f8;
    &:hover {
      cursor: pointer;
    }
  }
}
