@import '../../styles/variables';
@import '../../styles/colors';

.editor-header {
  position: relative;
  z-index: 1;

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  display: block;
  padding: $default-vertical-margin $default-horizontal-margin 0 $default-horizontal-margin;
  @include nav-shadow;
  background-color: white;
}

.editor-header__tabs {
  display: flex;
  width: 100%;
  max-width: $app-content-max-width;
  // margin: 0 auto;
  margin-top: 12px;

  .tab-selector {
    background-color: white;
    border: none;
    font-family: 'SkattaSans-Bold', sans-serif;
    flex: 1;
    padding: 6px 0 6px 0;
    cursor: pointer;

    &.is-selected {
      border-bottom: 2px solid $k-ui-orange;
    }
  }
}
