@import '../../styles/variables.scss';

.next-retailer .box.delivery-card {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-grow: 1;
  font-size: 0.8em;
  padding: $margin-half;
  margin-bottom: $margin-half;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.2);
  }

  > div {
    margin: 0 $margin-half;
  }

  @media screen and (max-width: $breakpoint-desktop) {
    justify-content: center;
    overflow: hidden;
    flex-wrap: nowrap;
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: $margin-half;
  }
  .basic-info {
    flex-grow: 1;
    h3 {
      font-size: $font-size-m;
      margin-bottom: $margin-quarter;
    }
  }
  .pull {
    align-items: center;
  }
  .caret-end {
    display: flex;
    align-items: center;
  }
}
