@import '../../styles/variables.scss';

.next-retailer .stampcard-image-editor {
  .drawer-footer {
    padding: $padding-default;
    display: flex;
    justify-content: flex-end;

    * + * {
      margin-left: $margin-default;
    }
  }

  .stampcard-image-editor-section {
    h2 {
      font-family: $font-default;
      font-size: $font-size-l;
    }

    &.section-split {
      display: flex;
      .left,
      .right {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .left {
        margin-right: $margin-half;
      }

      .right {
        margin-left: $margin-half;
      }
    }

    & + .stampcard-image-editor-section {
      margin-top: $margin-default;
    }
  }

  .stampcard-image-editor-backgrounds {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    .stampcard-image-editor-background {
      display: flex;

      .radio-button label {
        display: flex;
        align-items: center;
      }

      img {
        max-width: 90%;
      }
    }
  }

  .stampcard-image-editor-preview {
    display: flex;
    justify-content: center;
  }
}
