@import '../../retailer/next/styles/variables.scss';

.content-preview {
  height: 100%;
  overflow: auto;
  header {
    display: flex;
    padding: 15px 0 15px 0;
    align-items: center;

    .language-selector {
      margin: 0 16px 0 16px;
    }

    .email-preview-link {
      margin-left: auto;
    }
    .generate-link {
      display: flex;
      margin-left: auto;
      align-items: center;
      padding: 4px 5px;
    }

    .preview-url-container {
      display: flex;
      align-items: center;
      margin-left: auto;

      .preview-url {
        width: 20rem;
        font-size: $font-size-xxs;
      }
    }
  }

  .image-wrapper {
    min-width: 100%;
    border: 1px solid #f0f0f0;
  }
  .pdf-wrapper {
    min-width: 100%;
    height: 100%;
  }
  .error {
    text-align: center;
  }
  a {
    cursor: pointer;
  }
  img {
    max-width: 100%;
    object-fit: contain;
  }
}
