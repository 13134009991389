.next {
  .table-container {
    width: 100%;
  }

  table.styled {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    white-space: nowrap;
    background-color: white;

    .checkboxes {
      width: 20px;
      text-align: center;
    }

    thead {
      text-align: left;
      font-weight: 600;

      th {
        background-color: $k-ui-grey-10;
        line-height: 48px;
        position: sticky;
        top: 0;

        &.sortable {
          &.sorted {
            background-color: darken($k-ui-grey-10, 2%);
            &.asc:after {
              content: ' \2191';
              opacity: 0.4;
            }

            &.desc:after {
              content: ' \2193';
              opacity: 0.4;
            }
          }

          &:hover {
            cursor: pointer;
            background-color: darken($k-ui-grey-10, 2%);
          }
        }
      }
    }

    th,
    td {
      padding: 4px $sidebar-page-horizontal-padding;
      border-right: 1px solid $k-ui-grey-02;
      border-bottom: 1px solid $k-ui-grey-02;
    }

    tr:nth-child(even) {
      background-color: $k-ui-grey-02;
    }
    tr:hover {
      background-color: darken($k-ui-grey-02, 5%);
    }

    td {
      line-height: 36px;

      &.long {
        white-space: unset;
      }
    }

    a,
    button {
      display: inline-block;
      border: none;
      background-color: transparent;
      padding: 2px 0 0 0;
      line-height: normal;
      vertical-align: middle;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &:not(:first-child) {
        margin-left: 16px;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    img {
      vertical-align: middle;
    }
  }
}
