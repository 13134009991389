@import '../../../../styles/variables.scss';

.next-retailer .offer-form {
  .offer-form-footer {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    .btn + .btn {
      margin-left: $margin-quarter;
    }
  }
}
