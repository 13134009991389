@import '../../styles/colors';

.next {
  .push-notifications-sidebar {
    h3 {
      margin-bottom: 16px;
    }
  }
}

.push-notifications {
  display: flex;
  flex: auto;
  overflow: scroll;

  td {
    max-width: 500px;
    overflow: auto;
  }
}
