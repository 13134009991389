.help-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.4);
  .help-overlay-content {
    overflow-y: auto;
    background: white;
    min-width: 500px;
    max-width: 900px;
    padding: 30px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    .help-close {
      position: absolute;
      top: 15px;
      right: 15px;
      button {
        cursor: pointer;
        background: transparent;
        border: none;
      }
    }
  }

  h1,
  h2,
  h3 {
    margin-bottom: 1em;
  }
  h4,
  ul,
  p {
    margin-bottom: 0.5em;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  pre {
    font-size: 0.75em;
    background: #fafafa;
    padding: 8px 16px;
  }

  &.hidden {
    display: none;
  }
}
