@import '../components/next/styles/colors.scss';

.navigation {
  background: $background-navigation;
  width: $width-navigation;
  flex-shrink: 0;
  color: $color-font-inverse;
  overflow-y: auto;

  .name-logo {
    color: $color-logo-light;
    padding: $margin-default 0;
    margin: 0 $margin-double $margin-half;
    text-align: center;
    border-bottom: $border-navigation;
  }

  .list-title {
    font-size: $font-size-s;
    padding: $margin-half $margin-double 0;
  }

  .navigation-link + .navigation-link .link-content {
    border-top: $border-navigation;
  }

  &.k-ruoka {
    background: $theme-color-kruoka;
  }
  &.k-rauta {
    background: $theme-color-krauta;
  }
  &.k-auto {
    background: $theme-color-kauto;
  }
  &.onninen {
    background: $theme-color-onninen;
  }
}

.navigation-link {
  font-family: $font-default-bold;
  font-size: $font-size-l;

  .link-content {
    padding: $margin-default 0;
    margin: 0 $margin-double;
    height: $size-navigation-icon;
    display: flex;
    align-items: center;

    .icons {
      height: 100%;
      flex: 0 0 $size-navigation-icon;
      padding-right: $margin-default;

      img {
        height: 100%;
        max-width: 100%;
      }
    }

    .link-icon.active {
      display: none;
    }

    .link-text {
      line-height: 1.1;
    }
  }

  &.disabled {
    pointer-events: none;
    color: $color-navigation-link-disabled;
  }

  &.expandable {
    .expand-container {
      padding-bottom: $margin-smaller;

      .link-content {
        padding: $margin-mini 0 $margin-mini $size-navigation-icon + $margin-default;
        font-size: 1rem;
        font-family: $font-default-medium;
        border-top: 0;
      }
    }

    .link-container {
      .link-content {
        position: relative;

        &:after {
          content: '';
          background: url('../images/arrow-down-nega.svg');
          position: absolute;
          right: 0;
          top: calc(50% - #{$size-arrow-down} / 2);
          height: $size-arrow-down;
          width: $size-arrow-down;
        }
      }

      &.expanded .link-content {
        padding-bottom: $margin-half;

        &:after {
          top: calc(50% - #{$size-arrow-down} / 2 + #{$margin-half} / 2);
        }
      }

      &.active .link-content:after {
        background: url('../images/arrow-down-lighter.svg');
      }
    }
  }
}

.navigation-link,
.link-container {
  &.active {
    color: $color-active;

    .link-content .link-icon {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.mobile-navigation {
  width: $width-navigation-mobile;

  .user-navigation {
    justify-content: space-between;
    padding: $margin-half;
  }

  .top-navigation > .select {
    margin: $margin-quarter $margin-half $margin-half;
  }

  .select-container {
    margin: 0 $margin-half $margin-half;
  }

  .navigation-link {
    .link-content {
      padding: $margin-smaller 0;
      margin: 0 $margin-half;

      .icons {
        height: $size-navigation-icon-mobile;
        flex-basis: $size-navigation-icon-mobile;
        padding-right: $margin-smaller;
      }
    }

    &.expandable .expand-container {
      padding-bottom: $margin-half;

      .link-content {
        padding: 0 0 0 $size-navigation-icon-mobile + $margin-smaller;
      }
    }
  }

  .list-title {
    padding: $margin-quarter $margin-half 0;
  }
}

.breadcrumbs {
  color: $color-slight-emph;
  font-family: $font-default-bold;
  display: flex;
  align-items: center;
  margin-bottom: $margin-bigger;

  a {
    display: flex;
    align-items: center;
  }

  .back-arrow {
    margin: 0 $margin-default $margin-mini 0;
    height: $size-arrow-back;
    width: $size-arrow-back;
  }

  .caret {
    margin: $margin-mini $margin-quarter 0;
    height: $size-arrow-caret;
    width: $size-arrow-caret;
    transform: rotateZ(-90deg);
  }

  .light {
    color: $color-label;
    font-family: $font-default;
  }
}
