@import '../../styles/colors';

.next .weekday-select {
  .day-pill {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    border-radius: 16px;
    line-height: 12px;
    color: $copy-text-color;
    background-color: $k-ui-grey-30;
    white-space: nowrap;
    padding: 8px 12px;
    margin: 0 2px 2px 0;
    font-family: 'SkattaSans-bold', sans-serif;

    &.selected {
      background-color: $k-blue;
      color: white;
    }
  }
}
