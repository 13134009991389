@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/utils';

.best-performing-title {
  padding: $default-vertical-margin;
  background-color: white;
  width: 100%;
}

.next {
  .best-performing-sidebar {
    h3 {
      margin-bottom: 16px;
    }
  }
}

.best-performing {
  overflow: scroll;

  td {
    max-width: 500px;
    overflow: auto;
  }
}
