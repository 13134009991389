@import '../../../next/styles/variables.scss';

.spinner {
  display: inline-block;
  position: relative;
  top: calc(50% - ($size-spinner-icon / 2));
  left: calc(50% - ($size-spinner-icon / 2));
  width: $size-spinner-icon;
  height: $size-spinner-icon;
  animation: spin 1s linear infinite;

  &--margin-top {
    margin-top: $default-horizontal-margin;
  }

  &--margin-bottom {
    margin-bottom: $default-horizontal-margin;
  }

  &--margin-right {
    margin-right: $default-vertical-margin;
  }

  &--margin-left {
    margin-right: $default-vertical-margin;
  }

  &--unset {
    position: unset;
  }
}
