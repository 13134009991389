@import '../../styles/colors';
@import '../../styles/variables';

.dropdown {
  $select-box-height: 36px;
  display: inline-block;
  position: relative;
  user-select: none;
  font-size: 14px;

  .half-wide &,
  .third-wide & {
    width: 100%;
  }

  svg {
    pointer-events: none;
  }

  &__selected,
  &__list {
    background-color: white;
    border: 1px solid $k-ui-border-grey;
    border-radius: $border-radius-small;
    display: inline-block;
    min-width: 0px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
  }

  &__selected {
    padding: 0px 12px;
    display: flex;
    line-height: 36px;
    align-items: center;
    justify-content: space-between;
    width: 250px;
  }

  &__list {
    position: absolute;
    z-index: 1;
    top: $select-box-height;
    left: 0;
    display: none;

    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;

    &.is-visible {
      display: inline-block;
    }

    li {
      padding: 4px 12px;

      &:hover {
        background-color: $k-ui-background-grey;
      }

      &.is-selected {
        color: $k-ui-orange;
      }
    }

    span {
      padding: 4px 12px;
    }
  }

  &.disabled .dropdown__selected {
    background-color: $k-ui-grey-10;
  }

  .dropdown-list {
    max-height: 300px; /* Set an appropriate maximum height to enable scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-left: 0;
  }
}
