.select-with-label {
  flex-wrap: wrap;

  .select-text {
    font-size: $font-size-l;
    padding-right: $margin-default;
  }
}

.select,
.options {
  color: $color-selected;
  border: $border-select;
  border-radius: $border-radius-button;
  background: $background-select;
  box-sizing: border-box;
  font-family: $font-default-bold;
  font-size: $font-size-base;

  &.simple {
    color: $color-font;
    font-family: $font-default;
    border: $border-input-number;
    border-radius: $border-radius-box;

    &.disabled {
      border: $border-select;
      background: $background-input-disabled;
      pointer-events: none;
    }
  }
}

.select {
  padding: 0;
  margin: $margin-quarter 0;

  .selected {
    position: relative;
    overflow: hidden;

    > * {
      padding: $margin-half $margin-default;
    }

    .hidden-text {
      visibility: hidden;
      min-width: 150px;
      white-space: nowrap;
    }

    input {
      border: 0;
      border-radius: 0;
      margin: 0;
      color: $color-selected;
      font-family: $font-default-bold;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      &::placeholder {
        color: $color-selected;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        color: $color-selected;
      }

      &::-ms-input-placeholder {
        color: $color-selected;
      }

      &:focus {
        outline: 0;

        &::placeholder {
          color: $color-active;
        }

        &::-ms-input-placeholder {
          color: $color-active;
        }
      }
    }
  }

  &.selectable {
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      background: url('../images/arrow-down.svg');
      position: absolute;
      right: $margin-default;
      top: calc(50% - #{$margin-half} / 2);
      height: $size-arrow-down;
      width: $size-arrow-down;
      margin-top: 1px;
    }

    &.simple:after {
      background: url('../images/arrow-down-dark.svg');
    }

    .selected {
      margin-right: $margin-bigger;
    }
  }
}

.options {
  display: none;
  position: absolute;
  padding: $margin-quarter 0;
  max-height: 50vh;
  min-width: 100%;
  overflow: auto;

  .option {
    cursor: pointer;
    padding: $margin-quarter $margin-default;

    &.disabled {
      color: $color-select-disabled;
      cursor: initial;
    }
  }
}

.select-container {
  position: relative;

  &.open {
    .select.selectable:after {
      transform: scaleY(-1);
      margin-top: 0;
    }

    .options {
      display: block;
      z-index: 100;
    }
  }
}
