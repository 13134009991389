@import '../../../retailer/next/styles/variables.scss';
@import '../../../../components/next/styles/colors.scss';

$size-product-image-small: 50px;

.next-retailer .product-select,
.next-retailer .stampcard-product-picker,
.next .product-select {
  .product-search-field {
    margin-bottom: $margin-half;
  }

  .search-result {
    cursor: pointer;

    &.disabled {
      cursor: initial;

      > * {
        opacity: 0.5;
      }

      .image-container img {
        opacity: 0.5;
      }

      .title {
        color: $color-light;
        justify-content: left;
        .filtered-by-unit {
          color: red;
          padding-left: $margin-half;
          font-size: $font-size-s;
        }
      }
    }

    &.product-item {
      display: flex;
      align-items: center;
      padding: $margin-quarter $margin-half;
      border-bottom: 1px solid $color-lightest-grey;

      .image-container {
        margin-right: $margin-half;
        width: 50px;
        display: flex;

        img {
          max-height: 50px;
          max-width: 50px;
          margin: auto;
        }
      }

      .item-details {
        margin: $margin-half 0;
        flex-grow: 1;

        .title {
          font-family: $font-default-bold;
        }
        .label {
          font-size: $font-size-xs;
          color: $copy-text-color;
        }
      }
      .price-container {
        text-align: right;
        .title {
          font-family: $font-default-bold;
          display: flex;
          justify-content: flex-end;
        }
        .label {
          font-size: $font-size-xs;
          color: $copy-text-color;
          white-space: nowrap;
        }
      }
    }
  }

  .product-search-results {
    margin-top: $margin-mini;
    margin-bottom: $margin-double;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;

    &:empty {
      display: none;
    }

    .result-title {
      color: $color-light;
      font-size: $font-size-s;
      font-family: $font-default-medium;
      text-transform: uppercase;
      letter-spacing: -0.2px;
      cursor: initial;
    }

    .show-more {
      padding: $margin-half;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-k-orange;
      font-family: $font-default-bold;
      cursor: pointer;

      * {
        pointer-events: none;
      }

      img {
        margin-left: $margin-half;
        height: 11px;
        width: 11px;
      }
    }

    .no-results {
      padding: $margin-half;
      text-align: center;

      + .add-own-result {
        border-top: $border-divider;
      }
    }

    .loading-results {
      padding: $margin-half;
      display: flex;
      align-items: center;
      position: relative;
    }

    .show-more,
    .loading-results {
      + .search-result {
        border-top: 3px solid $color-divider;
      }
    }
  }
}
