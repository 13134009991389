@import '../../styles/variables.scss';

.next-retailer .drawer,
.next .drawer {
  display: none;
  &.open {
    display: block;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .drawer-content {
      position: fixed;
      background: white;
      width: 1366px;
      max-width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0px;
      overflow: hidden;
      @media screen and (max-width: $breakpoint-desktop) {
        box-sizing: border-box;
        width: 100%;
        left: 0;
      }
    }
  }
  .drawer-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 15;
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
