@import '../../styles/variables';

.next-retailer {
  .push-notification__instructions {
    line-height: 1.4;
  }

  .new-push-message {
    padding-bottom: 200px;
    height: 100%;

    header {
      background-color: white;
      text-align: center;
      margin-bottom: 20px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
      h1 {
        font-size: 1.25rem;
        padding: 10px 0;
        display: inline-block;
        margin-top: 10px;
      }
      .close-button {
        top: 15px;
        right: 15px;
      }
    }
    .new-push-message-content {
      padding-bottom: 100px;
    }
    h2 {
      font-family: 'SkattaSans-Regular', sans-serif;
      font-size: 1.25rem;
      margin-bottom: 10px;
    }

    textarea {
      height: 118px;
    }

    .push-form {
      display: flex;
      flex-wrap: wrap;

      h4 {
        font-size: 1rem;
        margin-bottom: 1rem;
      }

      .radio-button {
        margin-bottom: 10px;
        font-size: 14px;
      }

      &__row {
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;

        .column {
          flex: 0 1 50%;
          box-sizing: border-box;
          overflow-x: hidden;

          &:nth-child(odd) {
            padding-right: 12px;
          }

          &:nth-child(even) {
            padding-left: 12px;
          }
        }
      }
      .datepicker {
        width: 100px;
      }
      .small-text {
        font-size: 0.875rem;
      }

      .recipient-count {
        background-color: $color-lightest-grey;
        border-radius: $border-radius;
        text-align: center;
        font-size: 1rem;
        padding: 24px 12px;
        margin-bottom: 12px;

        em {
          color: $color-k-orange;
          font-size: 1.5rem;
          font-style: normal;
          font-family: $font-default-bold;
          vertical-align: text-bottom;
          display: inline-block;
          margin: 0 6px -8px 6px;
        }
      }

      .message-preview {
        padding: 12px;
        background-color: $color-lightest-grey;
        border-radius: $border-radius;
        box-sizing: border-box;
        max-width: 480px;
        overflow-x: hidden;

        .message-content {
          max-height: 200px;
          overflow-y: auto;
        }

        .title-row {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          svg {
            margin-left: -3px;
          }

          h2 {
            margin: 0 0 0 1rem;
          }
        }
      }

      .message-length {
        font-size: 12px;
        color: $color-grey;
        margin-left: 12px;
      }

      .message-status {
        padding: 12px;
        border-radius: $border-radius;
        margin-top: 32px;
        border-width: 2px;
        border-style: solid;
        height: 118px;
        box-sizing: border-box;

        &__text {
          width: 100%;
        }

        svg {
          display: inline-block;
          width: 40px;
          vertical-align: top;
        }

        span {
          display: inline-block;
          width: calc(100% - 120px);
          padding-left: 12px;
          box-sizing: border-box;
        }

        &.ok {
          border-color: $status-ok-border;
          background-color: $status-ok-background;
          svg > path {
            fill: $color-green;
          }
        }

        &.warn {
          border-color: $status-warn-border;
          background-color: $status-warn-background;
          svg > path {
            fill: $color-orange;
          }
        }

        &.error {
          border-color: $status-error-border;
          background-color: $status-error-background;
          svg > path {
            fill: $color-red;
          }
        }
      }
    }

    .MuiFormControl-root {
      width: 90px;
      margin-right: 12px;
    }

    .date-instruction {
      margin-top: 26px;
    }

    footer {
      position: fixed;
      top: 100%;
      padding: 12px 0;
      text-align: center;
      background-color: white;
      box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.07);
      width: 100%;
      margin-top: -60px;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        margin: 0 12px 0 12px;
      }
    }
  }
}
