.lander {
  background-color: $background-lander;
  background-image: url('../images/k_background.png');
  height: 100vh;

  .lander-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-y: auto;

    .lander-content {
      max-height: 100%;
      width: 90%;
      max-width: 480px;
      padding-top: $margin-default;

      .lander-box {
        background-color: $background-box;
        border-radius: $border-radius-box;
        padding: $margin-double * 2;

        @media screen and (max-width: $breakpoint-desktop) {
          padding: $margin-double;
        }

        h1,
        h2,
        p {
          padding-bottom: $margin-default;
        }

        .login-button {
          display: inline-block;
        }

        .long-left {
          text-align: left;
          padding-bottom: $margin-default;

          .emph {
            color: $color-font-emph;
          }

          p {
            padding-bottom: 0;
          }
        }

        .button {
          margin: $margin-quarter $margin-half;
        }
      }

      .disclaimer {
        color: $color-font-inverse;
        font-size: $font-size-s;
        padding: $margin-default $margin-double;
      }
    }
  }
}
