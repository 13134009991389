.next .settings {
  width: 100%;

  .siteOptions {
    padding: 16px 24px;

    .input-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0;

      textarea {
        flex-grow: 1;
      }
    }
  }

  header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .actions {
    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .add-dimension {
    display: flex;
    border: none;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    margin-bottom: 16px;
    font-size: 1rem;

    span,
    img {
      display: inline-block;
    }
    img {
      margin-left: 16px;
    }
  }
}
