@import 'variables';
@import 'typography';
@import 'form';
@import '../../../../components/next/styles/colors.scss';

/* Everything in NEXT retailer side should be wrapped in .next-retailer */
.next-retailer {
  aside.aside-drawer {
    z-index: 999;
  }

  ul {
    list-style: none;
    text-align: left;
  }

  *:focus {
    outline: 0;
  }

  /* Common overrides */
  .full-page {
    background: $color-light-blue;
  }
  .main-content .full-page-content {
    padding-top: $margin-default;
  }

  /* Detail / info texts */
  .detail-text {
    font-size: 13px;
    display: inline-block;
    margin-top: 6px;
    color: $color-grey;
  }

  /* Boxes */
  %box {
    padding: $margin-default;
    margin-bottom: $margin-default;
    border-radius: $border-radius;
    h3 {
      margin-bottom: $margin-default;
    }
  }
  .box {
    @extend %box;
    background: white;
    box-shadow: $box-shadow;
  }
  .box-nega {
    @extend %box;
    color: white;
  }

  .box-sections {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .filters {
    margin-bottom: $margin-half;

    > div,
    > nav {
      display: inline-block;
    }

    nav {
      margin-left: $margin-default;
      a {
        padding-bottom: 4px;
      }
      a.active {
        font-family: $font-default-bold;
        border-bottom: 2px solid $color-k-orange;
      }
      a + a {
        margin-left: $margin-half;
      }
      a:hover {
        border-bottom: 2px solid $color-k-orange;
      }
    }
  }

  .big-number-display {
    text-align: center;
    .big-number {
      display: block;
      font-family: $font-default-bold;
      font-size: 3.5em;
      text-transform: uppercase;
    }
  }

  .debug {
    display: none;
  }
}
/* theme colors */
.box-nega {
  &.k-ruoka {
    background-color: $theme-color-kruoka;
  }
  &.k-rauta {
    background-color: $theme-color-krauta;
  }
  &.k-auto {
    background-color: $theme-color-kauto;
  }
  &.onninen {
    background-color: $theme-color-onninen;
  }
}
