@import '../../styles/variables';

.drawer {
  .drawer-header {
    background: white;
    box-shadow: $box-shadow;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px;
    z-index: 2;
    h2 {
      text-align: center;
      font-size: $font-size-xl;
      margin: 0;
    }
  }
  .drawer-footer {
    background: white;
    box-shadow: $box-shadow-inverse;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .drawer-content-wrapper {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .drawer-content-inside {
      padding: $margin-double $margin-default;
      margin-top: 40px;
      padding-bottom: 250px;
      @media screen and (max-width: $breakpoint-desktop) {
        padding: $margin-half;
        margin-top: 60px;
        padding-bottom: 250px;
      }
    }
  }
}
