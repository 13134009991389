@import '../../styles/variables.scss';
@import '../../../../../components/next/styles/colors.scss';

.next-retailer .news-page {
  .news {
    .news-item {
      display: flex;
      background: #fff;
      padding: $margin-smaller * 0.5;
      border-radius: $border-radius;

      @at-root #{&}--left {
        display: inline-block;
        vertical-align: top;
        max-width: 300px;
        margin-right: $margin-smaller * 0.5;

        img {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          object-fit: contain;
        }
      }

      @at-root #{&}--right {
        flex-direction: column;
        display: flex;
        flex: 1;

        .news-item--header {
          display: flex;
          justify-content: space-between;

          .header--title {
            font-family: $font-default-bold;
            font-size: $font-size-d;
          }

          .header--timestamp {
            color: $color-grey;
            font-size: $font-size-s;
            padding-right: 4px;
          }
        }

        .news-item--content {
          margin-top: $margin-smaller;

          h1,
          h2,
          h3,
          h4 {
            font-size: $font-size-base;
            font-family: $font-default;
          }
        }
      }

      & + .news-item {
        margin-top: $margin-smaller;
      }
    }
  }

  .actions {
    margin-top: $margin-smaller;

    .btn {
      background-color: #fff;
    }
  }
}
