.user-navigation.retailer {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  .right-text {
    font-size: $font-size-l;
    font-family: $font-default-bold;
    padding-right: $margin-default;
    white-space: nowrap;
    text-align: left;
    display: flex;
    align-items: center;

    span {
      white-space: normal;
    }

    img {
      padding-left: $margin-default;
      height: $size-arrow-down;
      width: $size-arrow-down;
    }
  }

  .right-image {
    flex: 0 0 $size-header-image;
    width: $size-header-image;
    height: $size-header-image;
    border-radius: $size-header-image * 0.5;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
    }
  }

  .retailer-menu {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 101;
    display: none;

    .info-navigation-links {
      width: 100%;
      text-align: left;
      background: $background-box;
      padding: $margin-half 0;
      color: $color-font-emph;

      .navigation-link .link-content {
        padding: $margin-smaller 0;
        border: none;
        height: auto;
      }

      .divider {
        margin: $margin-half 0;
      }
    }
  }

  &.open {
    .right-text img {
      transform: scaleY(-1);
    }

    .retailer-menu {
      display: block;
    }
  }

  .header & {
    align-self: stretch;
    justify-content: space-between;
    padding: $margin-default;
    margin-right: $margin-bigger;

    &.open {
      &,
      .retailer-menu {
        box-shadow: $box-shadow;
      }
    }

    .info-navigation-links {
      border-top: $border-divider;

      .link-content {
        margin: 0 $margin-default;
      }
    }
  }
}
