@import '../../styles/editor';
@import '../../styles/colors';

.program {
  flex: 1 1 auto;
  .editor-header {
    position: relative;
    z-index: 20;

    &__actions {
      .editor-status {
        display: inline-block;

        &.ok {
          color: $status-green;

          svg > path {
            fill: $status-green;
          }
        }
        &.warning {
          color: $k-ui-orange;

          svg > path {
            fill: $k-ui-orange;
          }
        }
        &.error {
          color: $status-red;

          svg > path {
            fill: $status-red;
          }
        }

        > span {
          display: flex;
          align-items: center;
          margin-right: $default-vertical-margin;

          svg {
            width: 36px;
            height: 36px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .dropdown {
    margin-right: 16px;

    &__selected,
    &__list {
      min-width: 400px;
    }
  }

  .delivery-template-editor-overlay {
    width: 100%;
    bottom: 0;
    height: 100%;
    background-color: $k-ui-background-grey;
    display: flex;
  }

  .editor-section.delivery-templates {
    padding: 0;

    header {
      padding: $default-vertical-margin $default-horizontal-margin;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .add-delivery-template {
        display: flex;
        border: none;
        background-color: transparent;
        cursor: pointer;
        align-items: center;
        font-size: 1rem;
        img {
          display: inline-block;
          margin-left: 12px;
        }
      }
    }

    table.editor-grid {
      white-space: normal;
      thead th {
        line-height: 16px;
        padding: 8px 16px;
      }
      .checkbox label:before {
        margin: 0;
      }
      td .nowrap {
        white-space: nowrap;
      }
    }
  }
}
.next .drawer-pane {
  .drawer-options {
    position: relative;
    top: 15px;
    left: 20px;
    z-index: 2;
    display: flex;
    align-items: center;
    padding-top: 1rem;
  }

  p {
    margin-right: 1rem;
  }

  .copy-here {
    border: 2px solid $k-ui-orange;
    color: $k-ui-orange;
    border-radius: $border-radius-normal;
    background-color: white;
    text-transform: uppercase;
    font-family: 'SkattaSans-Bold', sans-serif;
    display: inline-block;
    padding: 4px 8px;
    cursor: pointer;
  }
}

.delete-delivery-template {
  border: none;
  background-color: transparent;
}
