@import '../../styles/colors';

.radio-button {
  display: flex;
  align-items: center;

  input[type='radio'] {
    display: none;
  }

  label {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0;
    padding: 0;
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 2px solid $k-ui-border-grey;
      border-radius: 50%;
      margin: -2px 8px 0 0;
      vertical-align: middle;
    }
  }

  input[type='radio']:checked + label:before {
    box-shadow: 0 0 0 2px white inset;
    background-color: $k-ui-orange;
    border: 2px solid $k-ui-orange;
  }
}
.radio-button-hidden {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  pointer-events: none;

  input[type='radio'] {
    display: none;
  }

  label {
    display: contents;
    font-size: 0.875rem;
    margin-bottom: 0;
    padding: 0;

    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 2px solid $k-ui-border-grey;
      border-radius: 50%;
      margin: -2px 8px 0 0;
      vertical-align: middle;
      visibility: hidden;
    }
  }
}
