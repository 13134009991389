@import '../../styles/variables';
@import '../../styles/colors';

.sidebar-container {
  display: flex;
  flex: 1 1 auto;
  min-width: $app-content-max-width;

  > * {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .sidebar {
    padding: $default-vertical-margin $sidebar-page-horizontal-padding;
    border-right: 2px solid $k-ui-table-grey;
    background-color: white;
    display: inline-block;
    width: 290px;
    flex-grow: 0;
    flex-shrink: 0;
    &__title-wrapper {
      display: flex;
      flex-direction: row;
    }
    &__title {
      margin-bottom: 16px;
      margin-right: 16px;
    }
  }

  .filter-group {
    margin-bottom: $default-vertical-margin;
  }

  .add-button.sidebar-button {
    background-color: $k-ui-orange;
    color: white;
    display: flex;
    padding: 8px 24px;
    border-radius: 4px;
    margin: 12px auto 24px auto;
  }
}
.search-box-wrapper {
  margin-bottom: $default-vertical-margin;

  .search-box {
    padding: 0 $sidebar-page-horizontal-padding;
    border: 1px solid $k-ui-border-grey;
    border-radius: 21px;
    height: 42px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 400px;

    &__icon {
      height: 16px;
      display: inline-block;
      margin: 0 1rem;
    }

    &__input {
      height: 32px;
      margin-left: 12px;
      font-size: 14px;
      border: none;
    }
  }

  .detail-text {
    font-size: 13px;
    display: inline-block;
    margin-top: 6px;
    color: $k-ui-grey-50;
  }
}
