@import '../../styles/editor';
@import '../../styles/colors';

.dimension-options {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .add-dimension-option {
    display: flex;
    border: none;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    margin-bottom: 16px;
    font-size: 1rem;

    span,
    img {
      display: inline-block;
    }
    img {
      margin-left: 16px;
    }
  }
}

.dimension {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  .editor-header {
    position: relative;
    z-index: 20;

    &__actions {
      .editor-status {
        display: inline-block;

        &.ok {
          color: $status-green;

          svg > path {
            fill: $status-green;
          }
        }
        &.warning {
          color: $k-ui-orange;

          svg > path {
            fill: $k-ui-orange;
          }
        }
        &.error {
          color: $status-red;

          svg > path {
            fill: $status-red;
          }
        }

        > span {
          display: flex;
          align-items: center;
          margin-right: $default-vertical-margin;

          svg {
            width: 36px;
            height: 36px;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .page > div {
    flex: 1 1 auto;
  }
}
