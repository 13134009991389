@import '../../styles/colors';

.chain-selector {
  .chain-name {
    cursor: pointer;

    &.not-selected {
      background-color: $k-ui-grey-30;
      color: $copy-text-color;
    }
  }
}
