@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/colors';

.next .concepts {
  position: relative;
  padding: 0;
  background-color: $k-ui-background-grey;
  .table-container {
    .concept-grid {
      white-space: normal;
      .checkbox label:before {
        margin: 0;
      }
      td .nowrap {
        white-space: nowrap;
      }
    }
  }
  .concept-row.archived {
    color: $k-ui-grey-30;
  }
}
