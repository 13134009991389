.next .stores {
  width: 100%;

  header {
    padding: 16px 24px;
  }

  .actions {
    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

.next .stores-sidebar {
  .store-actions {
    .btn {
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }
  }
}
