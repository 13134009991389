@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .button {
    padding-top: $margin-half + 2px;
    padding-bottom: $margin-half - 2px;
  }

  .select .selected {
    white-space: nowrap;
    height: calc(1.4375em - 4px + 20px); // line-height - borders + padding

    input::-ms-clear {
      display: none;
    }
  }

  textarea {
    overflow: auto;
  }

  .main-content .box-divided.equal > div:not(.channel-select) {
    flex-basis: auto;
  }

  .box-right,
  .box-left,
  .front-page .small-box {
    .box-content {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .front-page.retailer .small-box {
    .button-container {
      max-width: 100%;
    }

    .number-container,
    .participation-number {
      flex-basis: auto !important;
    }
  }

  .participation-form,
  .campaign-form {
    .select-input .number {
      height: 1.22em;
    }

    .select-slider .slider-text {
      height: 0.75em;
    }
  }

  .participation-form {
    .select-input .label-box .channel-preview {
      display: flex;
      justify-content: center;

      .preview-container {
        flex-basis: 340px;
        overflow: hidden;
      }

      &.box-divided {
        justify-content: space-around;

        .preview-container {
          &:first-child {
            flex-basis: 250px;
          }

          &:last-child {
            flex-basis: 120px;
          }
        }
      }
    }
  }

  .header,
  .footer {
    .flex-content {
      margin: 0 (-$margin-default) 0 (-$margin-default - $margin-half);
      width: calc(100% + #{$margin-double});
    }
  }

  .number-bubble {
    padding-top: 0.25em;
    padding-bottom: 0;
    margin-bottom: $margin-quarter;
  }

  form .select-input .label-box input.number {
    padding-top: 0.15em;
  }

  .campaign-form .channel-select .numbers div.number {
    padding-top: 0.15em;
  }

  .campaign-form .promotion-editor .norm-price {
    margin-top: -4px;
  }

  .segment-box .box-right .status .status-number .number-bubble .number-text {
    padding-top: 0.3em;
  }

  .next-sendout-container .sendout-info .sendout-description {
    max-width: 100%;
  }

  .small-segment-boxes {
    width: calc(100% + #{$margin-default} - 1px);
  }

  .offer-display {
    max-width: $basis-offer-display;
  }

  .participation-summary .selected-channels .channel {
    max-width: none;
  }

  .offer-inputs .small-offer-image {
    position: relative;
  }

  .progress,
  .graph-content {
    .top-bar .legend .text {
      padding-top: 5px;
    }

    .progress-graph .graph-container {
      padding-bottom: 0;
    }
  }

  .navigation {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .breadcrumbs .caret {
    margin-top: -2px;
  }

  .campaign-form .big-preview-container.print .preview-image {
    width: 100%;
  }

  .promotion-container {
    flex-basis: $max-width-promotion;
  }

  .promotion-container .promotion-main h3 {
    max-width: 100%;
  }

  .promotion-editor {
    .sale-type .radio-button {
      margin-top: -5px;
    }
  }

  .segments-description-container {
    flex-basis: auto !important;
  }

  .length-slider .slider {
    &::-ms-track {
      border: none;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: $color-selected;
    }

    &::-ms-fill-upper {
      background: $color-selected;
    }

    &::-ms-thumb {
      height: $height-range-slider;
      width: $height-range-slider;
      margin-top: -3px;
      border-radius: $height-range-slider * 0.5;
      background: $background-slider-button;
      border: none;
    }

    &::-ms-tooltip {
      display: none;
    }
  }

  .add-promotion .add-circle div {
    padding-top: 30%;
  }

  .campaign-box.participating {
    .box-right {
      min-width: 300px;

      .box-content {
        height: 100%;
      }
    }

    .campaign-previews {
      min-width: auto !important;
    }

    .campaign-preview {
      display: block !important;
    }
  }

  .campaign-results .promotions-container .promotion-analytics {
    .promotion-container,
    .analytics-container {
      flex-basis: auto;
    }
  }

  .delivery-form {
    .content-field-product-container .add-product .add-circle div {
      height: 57px;
    }
  }
}
