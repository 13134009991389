@import '../../../retailer/next/styles/variables.scss';

.radio-pill {
  display: inline-block;
  position: relative;
  input {
    appearance: none;
    background: none;
    border: 2px solid;
    height: 100%;
    left: 0;
    opacity: 0.00001;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    cursor: pointer;
  }

  label {
    width: 100%;
    border-radius: 20px;
    padding: 5px 10px;
    background: $color-light-grey;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
  }

  input:checked + label {
    color: white;
    background: $color-k-orange;
  }
}
