/* font sizes */
$logo-font-size: 28px;

/* margins & paddings */
$default-horizontal-margin: 24px;
$half-horizontal-margin: 12px;
$default-vertical-margin: 16px;
$half-vertical-margin: 8px;

$sidebar-page-horizontal-padding: 16px;

/* other */
$app-content-max-width: 1080px;
$border-radius-normal: 4px;
$border-radius-small: 2px;

$header-bar-height: 64px;
$nav-bar-height: 55px;
// use the previous two values here; scss doesn't seem to understand variables in calc
$body-height: calc(100vh - calc(64px + 55px));

@mixin nav-shadow() {
  box-shadow: 0 3px 6px #ccc;
}

@mixin block-shadow() {
  box-shadow: 0 0 6px #ccc;
}

@mixin block-shadow-dark() {
  box-shadow: 0 0 8px #666666;
}

$size-spinner-icon: 20px;

$breakpoint-desktop: 767px;
