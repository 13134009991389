@import '../../../../styles/variables';

.next-retailer .delivery-form .delivery-channels {
  .channel {
    h3 {
      margin-bottom: $margin-default;
    }
    .box-sections {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: $breakpoint-desktop) {
        flex-direction: column;
        > * {
          margin-bottom: $margin-default;
        }
      }

      .channel-preview {
        width: 200px;
        height: 160px;
        .preview-wrapper {
          width: 200px;
          max-height: 160px;
          overflow: hidden;
          box-shadow: $box-shadow;
        }
        margin-right: $margin-double;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media screen and (max-width: $breakpoint-desktop) {
          margin-right: 0;
        }
        img {
          max-width: 100%;
        }
        .preview-icon {
          position: absolute;
          top: -0.75em;
          right: -0.75em;
          background: $color-k-orange;
          border-radius: 1.25em;
          width: 2.5em;
          height: 2.5em;
          svg {
            position: relative;
            top: 0.5em;
            left: 0.5em;
            path {
              fill: white;
            }
          }
        }
        &.has-thumb {
          a {
            align-self: start;
            width: 100%;
          }
        }
      }

      .channel-options {
        flex-grow: 1;
        @media screen and (max-width: $breakpoint-desktop) {
          width: 100%;
        }
        .recipients-displays {
          display: flex;
          justify-content: space-around;
          margin-bottom: $margin-default;
          .number-display {
            text-align: center;
            label {
              display: block;
            }
            .num {
              font-family: $font-default-bold;
              font-size: $font-size-xxl;
              &.clickable {
                cursor: pointer;
              }
            }
            .num-input {
              margin-top: $margin-quarter;
              width: 80px;
            }
          }
        }

        &.not-selected {
          .slider {
            background-color: $color-middle-grey;
          }
        }
      }

      .channel-actions {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        min-width: 22%;
        > * {
          display: block;
        }
        .toggle-button {
          margin-bottom: $margin-quarter;
        }
        .validation-error {
          display: inline-block;
          color: white;
          background-color: $color-red;
          border-radius: 12px;
          width: 26px;
          line-height: 24px;
          margin-left: 5px;
        }
      }
    }
  }

  .channel + .channel {
    margin-top: $margin-default;
    padding-top: $margin-default;
    border-top: 1px solid $color-light-grey;
  }

  .targeted-users {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    margin-top: 32px;

    &__value {
      color: $color-red;
      font-family: $font-default-bold;
      font-size: $font-size-l;
      margin: 0 6px 0 6px;
    }
  }
}
