@import '../../../next/styles/colors.scss';
@import '../../../next/styles/variables.scss';
@import '../../../retailer/next/styles/variables.scss';

.next,
.next-retailer {
  .title {
    display: flex;
    justify-content: space-between;
  }

  .graph-container {
    background-color: $k-ui-background-grey;
    max-width: 100%;
    overflow-x: auto;
    @include block-shadow();
    background-color: white;
    border-radius: $border-radius-normal;
    padding: $default-vertical-margin $default-horizontal-margin;
    position: relative;
    margin-bottom: 20px;
  }

  .background-grid {
    path,
    line {
      stroke: $k-ui-grey-30;
    }
    text {
      stroke: $k-ui-grey-50;
    }
  }

  .element-box {
    cursor: pointer;
  }

  .month-names {
    path,
    line {
      display: none;
    }
    text {
      display: block;
      text-align: right;
    }
  }

  .weekends {
    .domain {
      display: none;
    }
  }

  .tooltip {
    background-color: white;
    color: $k-black;
    border-radius: $border-radius-normal;
    width: 340px;
    height: 160px;
    display: none;
    position: absolute;
    @include block-shadow-dark();
    z-index: 100;

    &__content {
      border-radius: $border-radius-normal;
      position: relative;
      z-index: 10;
      background-color: white;
      padding: 8px 12px;

      header {
        margin-bottom: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $k-ui-border-grey;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 17px;
          font-weight: 100;
          line-height: 1;
        }
      }

      p {
        font-size: 13px;

        b {
          font-family: $font-default-bold;
          font-size: 12px;
          display: inline-block;
          margin: 0 7px 4px 0;
        }
        span {
          display: block;
        }
      }

      footer {
        text-align: center;
        padding-top: 12px;
      }
    }

    &:before {
      content: '';
      transform: rotate(45deg);
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      left: calc(50% - 22px);
      top: 14px;
      left: -4px;
      background-color: white;
    }
  }
}
