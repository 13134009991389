@import '../../styles/colors';
@import '../../styles/variables';

.content-template-editor {
  &__content {
    display: none;
  }

  &__content.is-expanded {
    display: block;
  }

  .form-control {
    position: relative;
    padding-top: $default-vertical-margin;
    .help-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .invalid-json {
    color: $status-red;
    display: inline-block;
  }

  &__slots {
    margin-top: 24px;
  }
  .content-slots-title {
    margin-bottom: 8px;
  }

  .slot-editor {
    margin-bottom: 24px;
    padding: $default-vertical-margin $default-horizontal-margin;
    background-color: $k-ui-grey-02;

    .slot-details {
      margin: 12px 0 12px 0;

      span {
        display: inline-block;
        margin: 0 8px 12px 0;

        &:not(:last-child) {
          &:after {
            content: '·';
            display: inline-block;
            margin-left: 8px;
            font-size: 24px;
          }
        }
      }
    }

    .content-block-name {
      margin: 24px 0 16px 0;
    }

    .selected-blocks {
      margin-top: 24px;

      h3 {
        margin-bottom: 16px;
      }

      .toggle-container,
      .delete-container {
        text-align: center;
        width: 200px;

        .toggle-button,
        .delete-button {
          vertical-align: middle;
        }

        .delete-button {
          svg {
            width: 36px;
            height: 36px;
          }
        }
      }
    }
  }

  .detail-text {
    font-size: 13px;
    display: inline-block;
    margin-top: 6px;
    color: $k-ui-grey-50;

    pre {
      margin: 0;
    }
  }

  .print-recipe-editor {
    .title-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .recipe-layer,
    .recipe-element,
    .font-selector {
      background-color: $k-ui-background-grey;
      padding: $default-vertical-margin $default-horizontal-margin;
      margin-bottom: $default-vertical-margin;
      margin-bottom: 24px;
    }

    .font-selector {
      display: flex;
      flex-wrap: wrap;

      .title-row {
        margin-bottom: 12px;
      }

      .checkbox {
        flex-basis: 33%;
        margin-bottom: 4px;
      }
    }

    .fonts-missing {
      color: $status-red;
    }
  }
  .required-missing {
    color: $status-red;
  }
}
