@import '../../../retailer/next/styles/variables.scss';

.next-retailer,
.next {
  .btn {
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    padding: $margin-quarter $margin-default;
    text-transform: uppercase;
    font-family: $font-default-bold;
    font-size: $font-size-m;
    border-radius: 5px;
    text-align: center;
    border: 2px solid transparent;
    user-select: none;

    &.btn-disabled {
      color: #444;
      background: #abacac;
      border: none;
    }

    &.btn-default {
      color: white;
      background: $color-k-orange;

      &.btn-disabled {
        color: #444;
        background: #abacac;
        border: none;
      }
    }
    &.btn-inverse {
      color: $color-k-orange;
      background: transparent;

      &.btn-disabled {
        color: #444;
      }
    }

    &.btn-bordered {
      color: $color-k-orange;
      background: transparent;
      border: 2px solid $color-k-orange;

      &.btn-disabled {
        color: #444;
        border-color: #444;
      }
    }

    &.btn-disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
    &.btn-large {
      padding: $margin-half $margin-default;
    }
  }
}
