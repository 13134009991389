@import '../../styles/variables';

.targeted-users {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  margin-top: 32px;

  &__value {
    color: $color-red;
    font-family: $font-default-bold;
    font-size: $font-size-l;
    margin: 0 6px 0 6px;
  }
}
