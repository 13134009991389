@import '../../../retailer/next/styles/variables.scss';

.product-input {
  label {
    margin: $margin-half 0;
  }

  .input-text.form-control {
    textarea {
      min-height: 5rem;
    }
  }
}
