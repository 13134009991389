@import '../../../retailer/next/styles/variables.scss';

.git-hash {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: $margin-default;
  margin: 0 20px;
  font-size: $font-size-xs;
  color: $color-middle-grey;
  .git-hash-text {
    display: flex;
    flex-direction: row;
  }
  .version {
    font-size: $font-size-xs;
    margin: 0px;
    &:not(:last-child) {
      margin: 0px;
    }
    &:last-child,
    &:nth-last-child(1) {
      margin-left: 5px;
    }
  }
}

.user-menu .git-hash {
  font-size: $font-size-xxs;
  right: 0;
  bottom: auto;
  flex-direction: row;
  .date {
    padding-top: 2px;
    margin-left: 5px;
  }
}

footer .git-hash,
.footer-buttons .git-hash {
  color: $color-cool-grey;
  font-size: $font-size-xxs;
  flex-direction: row;
  left: 0;
  .date {
    padding-top: 2px;
    margin-left: 5px;
  }
}
