@import '../../styles/variables.scss';

.next-retailer .stampcard-list {
  .stampcard-list-header {
    margin-bottom: $margin-default;
  }

  .stampcard-list-header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .stampcard-list-own,
  .stampcard-list-create {
    h2 {
      font-size: $font-size-xl;
      font-family: $font-default;
    }
  }

  .stampcard-list-own {
    margin-bottom: $margin-default;
  }

  .stampcard-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $font-size-s;
    margin-bottom: $margin-half;

    .stampcard-box-content {
      margin: 0 $margin-smaller;
      display: flex;
      flex-direction: column;
      flex: 1;

      .stampcard-title {
        display: flex;
        align-items: center;

        h3 {
          margin-bottom: $margin-quarter;
        }

        .stampcard-status {
          margin-left: $margin-half;
          font-size: $font-size-xs;
          font-family: $font-default-bold;
          color: #ff6900;
        }
      }
    }

    .stampcard-box-statistics {
      &.loading {
        background-color: #fff;
      }

      display: flex;
      flex: 2;
      border-radius: $border-radius;
      background-color: $color-light-grey;
      font-size: $font-size-s;
      justify-content: space-around;

      > div {
        padding: $padding-default * 0.5 $padding-default;
        text-align: center;

        > p {
          color: $color-k-orange;
          font-weight: bold;
        }
      }
    }

    .stampcard-box-actions {
      a {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
