@import '../../styles/variables';

.next .pricing-items {
  padding: $default-vertical-margin 0;

  header {
    display: flex;
    justify-content: space-between;

    .add-button.program {
      display: flex;
      align-items: center;
    }

    h2 {
      margin-bottom: $default-vertical-margin;
    }
  }

  .actions {
    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
