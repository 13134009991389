@import '../../../retailer/next/styles/variables.scss';
@import '../../../next/styles/colors.scss';

.input-csv-container {
  p {
    text-align: center;
    margin-right: $margin-half;
  }
  em {
    font-size: $font-size-m;
    margin-right: $margin-half;
  }
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $margin-default;
  margin-top: $margin-half;
  border-radius: 2px;
  border: 1px solid $color-grey;
}
