@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/utils';

.content-block-editor {
  &__advanced {
    margin-bottom: 12px;
  }

  .form-control {
    position: relative;
    padding-top: $default-vertical-margin;
    .help-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
