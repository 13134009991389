$path-font: '../fonts';

@font-face {
  font-family: 'SkattaSans-Regular';
  src: url('#{$path-font}/SkattaSansRegular.eot');
  src:
    url('#{$path-font}/SkattaSansRegular.eot?') format('embedded-opentype'),
    url('#{$path-font}/SkattaSansRegular.woff') format('woff'),
    url('#{$path-font}/SkattaSansRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SkattaSans-Bold';
  src: url('#{$path-font}/SkattaSansBold.eot');
  src:
    url('#{$path-font}/SkattaSansBold.eot?') format('embedded-opentype'),
    url('#{$path-font}/SkattaSansBold.woff') format('woff'),
    url('#{$path-font}/SkattaSansBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SkattaSans-Medium';
  src: url('#{$path-font}/SkattaSansMedium.eot');
  src:
    url('#{$path-font}/SkattaSansMedium.eot?') format('embedded-opentype'),
    url('#{$path-font}/SkattaSansMedium.woff') format('woff'),
    url('#{$path-font}/SkattaSansMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SkattaSans-Black';
  src: url('#{$path-font}/SkattaSansBlack.eot');
  src:
    url('#{$path-font}/SkattaSansBlack.eot?') format('embedded-opentype'),
    url('#{$path-font}/SkattaSansBlack.woff') format('woff'),
    url('#{$path-font}/SkattaSansBlack.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
