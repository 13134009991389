@import '../../../retailer/next/styles/variables.scss';

.next .alert,
.next-retailer .alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 1em $margin-default;
  margin-bottom: $margin-half;
  overflow: hidden;
  position: fixed;
  z-index: 11111;
  bottom: 0px;
  width: 304px;
  right: 20px;
  animation: slide-up 0.4s ease;
  :before,
  :after {
    content: ' ';
    display: block;
    width: 0.5em;
    height: 100%;
    position: absolute;
    top: 0;
  }
  :before {
    left: 0;
  }
  :after {
    right: 0;
  }
  &.info {
    border: 2px solid $color-info;
    bottom: 50px;
    :before {
      background: $color-info;
    }
  }
  &.warning {
    border: 2px solid $color-warning;
    bottom: 50px;
    :before {
      background: $color-warning;
    }
  }
  &.success {
    border: 2px solid $color-success;
    bottom: 50px;
    :before {
      background: $color-success;
    }
  }
  &.error {
    border: 2px solid $color-error;
    bottom: 50px;
    :before {
      background: $color-error;
    }
  }
  .alert-message {
    width: 100%;
  }
  .alert-link {
    width: 100%;
    font-weight: bold;
    text-decoration: underline;
  }
  .alert-dismiss {
    background: transparent;
    border: none;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 10px;
      top: 10px;
      path {
        fill: #ccc;
      }
    }
  }
  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  a {
    width: 100%;
    font-weight: bold;
    text-decoration: underline;
  }
}
