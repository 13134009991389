@import '../../styles/variables.scss';

.next-retailer .front-page {
  .box-section {
    text-align: center;
    margin: 0 $margin-default;
    padding: 0 $margin-default;
    h3 {
      color: $color-k-orange;
    }
    p {
      font-size: $font-size-d;
    }
  }

  .box-section + .box-section {
    border-left: 1px solid $color-light-grey;
    @media screen and (max-width: $breakpoint-desktop) {
      border: none;
    }
  }

  .analytics {
    .box {
      display: flex;
      margin: 0 (-$margin-quarter) $margin-default;
      @media screen and (max-width: $breakpoint-desktop) {
        flex-wrap: wrap;
      }
      .box-section {
        flex-basis: 0;
        flex-grow: 1;
        margin: $margin-quarter;
        position: relative;
        .info-popover {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  .news-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 (-$margin-quarter) $margin-default;
    justify-content: flex-start;
    margin-bottom: 32px;

    @media screen and (max-width: $breakpoint-desktop) {
      display: block;
    }

    .news-item {
      flex: 0 1 33%;
      margin: $margin-quarter;
      background-color: white;
      border-radius: $border-radius;
      padding: 16px 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 120px;

      &__title {
        font-size: 18px;
      }

      &__read-more {
        color: $color-k-orange;
      }
    }
  }

  .welcome {
    .box {
      display: flex;
      padding: $margin-default 0;
      min-height: 120px;
      @media screen and (max-width: $breakpoint-desktop) {
        flex-direction: column;
        min-height: 0;
      }
      .box-section {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
        margin: 0 $margin-default;
        padding: 0 $margin-default;
        @media screen and (max-width: $breakpoint-desktop) {
          &:not(:last-child) {
            margin-bottom: $margin-default;
          }
        }
        h3 {
          color: $color-k-orange;
        }
        p {
          font-size: $font-size-d;
        }
      }
    }
  }
}
