@import '../../styles/variables.scss';

.next-retailer .stampcard-viewer {
  .section {
    & + .section,
    & + h2 {
      margin-top: $margin-default;
    }
  }

  h2 {
    font-size: $font-size-xl;
    font-family: $font-default;
  }

  .basic-info {
    background-color: $color-dark-blue;
    color: #fff;
    border-radius: $border-radius;
    padding: $padding-default;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $margin-default;

      > div {
        display: flex;
        align-items: center;
      }

      h2 {
        margin-left: $margin-half;
        margin-bottom: 0;
        font-size: $font-size-xxl;
        font-family: $font-default-bold;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;

      .description {
        display: flex;
        flex: 2;
      }

      .dates {
        flex: 1;
        text-align: right;
      }
    }
  }

  .statistics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: $margin-default;
    grid-row-gap: 0px;

    > div {
      background-color: #fff;
      border-radius: $border-radius;
      padding: $padding-default;
      text-align: center;

      > p {
        color: $color-k-orange;
        font-weight: bold;
      }
    }
  }

  .configuration {
    display: flex;

    .left,
    .right {
      flex: 1;

      > div {
        background-color: #fff;
        border-radius: $border-radius;
        padding: $padding-default * 2;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          font-size: $font-size-huge;
          font-family: $font-default-bold;
          margin: 0;
        }
        .emph {
          color: $color-k-orange;
          font-weight: bold;
        }
      }
    }

    .left {
      margin-right: $margin-half;
    }

    .right {
      margin-left: $margin-half;
    }
  }

  .products {
    background-color: #fff;
    padding: $padding-default;
    border-radius: $border-radius;

    table {
      th {
        text-align: left;
        padding-right: $padding-default * 2;
      }

      td {
        padding-right: $padding-default * 2;
      }
    }
  }

  .preview {
    display: flex;

    .pre-wrap {
      white-space: pre-wrap;
    }

    div + h4 {
      margin-top: $margin-half;
    }

    .left,
    .right {
      flex: 1;
      background-color: #fff;
      border-radius: $border-radius;
      padding: $padding-default * 2;
    }

    .left {
      margin-right: $margin-half;
    }

    .right {
      margin-left: $margin-half;
    }

    .stampcard-preview-image-wrapper {
      padding: $padding-default;
      border-radius: $border-radius;
      box-sizing: border-box;
      background-color: $color-light-grey;
      max-width: 500px;
      margin: auto;

      .stampcard-image {
        display: block;
        max-width: 100%;
      }

      .stampcard-details {
        display: flex;
        border-top: 2px solid $color-k-orange;
        background-color: #fff;
        font-size: $font-size-s;
        padding: $padding-default * 0.5;

        .stampcard-details-left {
          display: flex;
          flex: 1;
          flex-direction: column;

          .stampcard-details-stores {
            margin-bottom: $margin-half;
            flex: 1;

            .stampcard-details-store {
              display: flex;
              align-items: center;

              img {
                max-height: 15px;
                margin-right: 5px;
              }
            }
          }

          .stampcard-details-name {
            font-weight: bold;
          }
        }

        .stampcard-details-right {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          .stampcard-status {
            font-size: $font-size-xs;
            border: 2px solid #ff6900;
            border-radius: 20px;
            padding: 0 $padding-default;

            &.active {
              background-color: #ff6900;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
