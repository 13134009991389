@import '../../styles/variables.scss';

.next-retailer .breadcrumb {
  margin-left: $margin-default;
  font-size: $font-size-s;
  z-index: 1;
  a {
    background: white;
    padding: 6px 10px;
    border-radius: $margin-default;
    font-family: $font-default-bold;
    color: $color-dark-blue;
    text-transform: uppercase;
  }
  svg {
    position: relative;
    top: 0.5em;
    margin: 0 $margin-half;
  }
}
