@import '../../../retailer/next/styles/variables.scss';

.next-retailer,
.next {
  .form-control {
    input,
    select,
    textarea {
      background: white;
      border: 1px solid $color-grey;
      border-radius: 3px;
      width: 100%;
      font-family: $font-default;
      font-size: 1em;
      display: block;
      padding: 6px 12px;
    }
    input:not(.MuiInputBase-input, [type='file']),
    select:not([multiple]) {
      height: 2.4em;
    }
    input:not(.MuiInputBase-input),
    textarea {
      &:disabled {
        background: $color-light-grey;
        border-color: $color-grey;
        color: #111;
      }
    }
    label {
      display: block;
      font-family: $font-default-bold;
      font-size: $font-size-s;
      color: $color-grey;
      margin-bottom: 2px;
    }
    input[type='radio'],
    input[type='checkbox'] {
      display: none;
    }
    .checkbox input[type='checkbox']:disabled + label {
      &:before {
        background-color: $color-light-grey;
        border-color: $color-grey;
      }
    }
    input:not(.MuiInputBase-input):read-only,
    input[type='number']:read-only {
      background-color: $color-light-grey;
      border-color: $color-grey;
    }
  }

  .input-container {
    position: relative;
    .input-suffix {
      position: absolute;
      right: 6px;
      top: 6px;
      color: $color-grey;
    }
  }

  .form-control + .form-control {
    margin-top: $margin-half;
  }

  .form-section + .form-section {
    margin-top: $margin-default;
    border-top: 1px solid $color-light-grey;
    padding-top: $margin-default;
  }

  @media screen and (max-width: $breakpoint-desktop) {
    .radio-button {
      margin-top: $margin-quarter;
      margin-bottom: $margin-quarter;
    }
  }
}
