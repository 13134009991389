@import '../../styles/variables';

.page {
  .content {
    padding: $default-vertical-margin $default-horizontal-margin;
    width: 100%;
    max-width: $app-content-max-width;
    margin: 0 auto;
  }
}
