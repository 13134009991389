@import '../../styles/variables';

.navigation-bar {
  display: inline-block;

  li {
    display: inline-block;
  }

  .navigation-link {
    margin-right: $default-horizontal-margin;
    display: inline-block;
  }
}
