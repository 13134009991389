@import '../../styles/colors';

.checkbox {
  input[type='checkbox'] {
    display: none;
  }

  label {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0;
    padding: 0;
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      border: 1px solid $k-ui-border-grey;
      border-radius: 2px;
      margin: -2px 8px 0 0;
      vertical-align: middle;
      background-color: white;
    }
  }

  input[type='checkbox']:checked + label:before {
    border-color: $k-ui-orange;
    background-color: $k-ui-orange;
    background-image: url('../../../../images/checkmark-nega.svg');
    background-size: contain;
  }

  input[type='checkbox']:disabled + label:before {
    border-color: $k-ui-border-grey;
    background-color: $k-ui-border-grey;
  }
}
