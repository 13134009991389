html,
body,
#root,
.app {
  height: 100%;
  position: relative;
}

html {
  font-size: $font-size-base;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (max-width: $breakpoint-desktop) {
    font-size: 13px;
  }
}

body {
  margin: 0;
  font-family: $font-default;
  color: $color-font;
}

a {
  color: inherit;
  text-decoration: none;
  display: block;
}

p,
li {
  > a {
    display: inline;
    color: $color-font-emph;
  }
}

h1,
h2,
h3,
h4 {
  font-family: $font-default-bold;
  line-height: 1.1;
  margin: 0;
  font-weight: normal;

  .emph {
    color: $color-font-emph;
  }
}

h1 {
  font-size: $font-size-xxxxl;

  @media screen and (max-width: $breakpoint-desktop) {
    font-size: $font-size-xxxl;
  }
}

h2 {
  font-size: $font-size-xxl;
}

h3 {
  font-size: $font-size-l;
}

h4 {
  font-size: $font-size-m;
}

.number {
  font-family: $font-default-bold;
  font-size: $font-size-huge;
  color: $color-slight-emph;
  white-space: nowrap;
  display: inline-block;
  padding: 0.05em 0.5em 0.1em;
  line-height: 1.15;

  @media screen and (max-width: $breakpoint-desktop) {
    font-size: $font-size-xxxxl;
  }

  .smaller {
    font-size: 0.7em;
  }
}

.number-bubble {
  color: $color-font-inverse;
  background: $background-bubble;
  border-radius: $border-radius-bubble;
  padding: 0.05em 0.5em 0.1em;
  white-space: nowrap;
  display: inline-block;
  line-height: 1.15;

  &.positive {
    background: $color-positive;
    padding-left: 0.3em;
  }

  &.negative {
    background: $color-negative;
    padding-left: 0.4em;
  }

  &.inverse {
    background: $background-bubble-inverse;
    color: $color-font;
  }

  .smaller {
    font-size: 0.7em;
  }
}

.label {
  font-size: $font-size-s;
  color: $color-label;
}

.label-title {
  font-size: $font-size-xs;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  font-family: $font-default-medium;
}

.index-bubble {
  font-size: $font-size-xxs;
  color: $color-font-inverse;
  background: $background-bubble;
  font-family: $font-default-bold;
  border-radius: 0.5em;
  height: 1.4em;
  width: 1.4em;
}

p {
  font-size: $font-size-m;
  line-height: 1.33;
  margin: 0;

  @media screen and (max-width: $breakpoint-desktop) {
    font-size: 1.16rem;
  }

  & + p {
    margin-top: $margin-half;
  }

  &.emph {
    font-family: $font-default-bold;
  }
}

strong {
  font-family: $font-default-bold;
  font-weight: normal;
}

ol {
  padding-left: 0;
  list-style-position: inside;

  @media screen and (max-width: $breakpoint-desktop) {
    font-size: 1.16rem;
  }
}

table {
  th {
    font-family: $font-default-bold;
    white-space: nowrap;
  }

  td.nowrap {
    white-space: nowrap;
  }
}

button,
input,
textarea {
  box-sizing: border-box;
  font-family: $font-default;
  // Needs to be minimun 16px to prevent zooming on mobile
  font-size: $font-size-base;
}

.button {
  // Needs to be minimun 16px to prevent zooming on mobile
  font-size: $font-size-base;
  font-family: $font-default-bold;
  display: inline-block;
  cursor: pointer;
  border: none;
  background: none;

  &:disabled {
    cursor: default;
  }
}

.button-default,
.button-disabled,
.button-inverse,
.button-secondary,
.tab-button {
  padding: $margin-half $margin-default;
  text-align: center;
  text-transform: uppercase;
  border-radius: $border-radius-button;
}

.button-default {
  background: $background-button-default;
  color: $color-font-inverse;
  border: $border-button-default;
}

.button-disabled {
  background: $background-button-disabled;
  color: $color-button-disabled;
  border: $border-button-disabled;
  user-select: none;
  pointer-events: none;
  cursor: default;
}

.button-inverse {
  background: $background-button-inverse;
  border: $border-button-inverse;
  color: $color-button-inverse;

  &.no-border {
    border-color: $background-button-inverse;
  }
}

.button-secondary {
  background: $background-button-secondary;
  border: $border-button-inverse;
  color: $color-font-inverse;
}

.button-loading {
  position: relative;
  padding-right: $margin-double + $size-loading-icon;

  &:after {
    content: url('../images/spinner.svg');
    position: absolute;
    right: $margin-default;
    top: $margin-half + 1px;
    height: $size-loading-icon;
    width: $size-loading-icon;
    animation: spin 1s linear infinite;
  }
}

.icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: $margin-half;

  img {
    height: $size-button-image;
    width: $size-button-image;

    & + * {
      margin-left: $margin-half;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.close-button {
  width: $size-close-button;
  height: $size-close-button;
  position: absolute;
  top: $margin-double;
  right: $margin-double;

  @media screen and (max-width: $breakpoint-desktop) {
    top: $margin-half;
    right: $margin-half;
  }

  img {
    width: 100%;
  }
}

.zoom-button {
  position: absolute;
  top: -$margin-half;
  right: -$margin-half;
  height: $size-zoom-button;
  width: $size-zoom-button;

  img {
    height: 100%;
    width: 100%;
  }
}

.box {
  background: $background-box;
  box-shadow: $box-shadow;
  margin-bottom: $margin-double;

  @media screen and (max-width: $breakpoint-desktop) {
    margin-bottom: $margin-smaller;
  }
}

.hidden {
  display: none !important;
}

.hidden-visibility {
  visibility: hidden !important;
}

.nowrap {
  white-space: nowrap;
}

.alert-banner {
  background: $color-negative;
  color: $color-font-inverse;
  text-align: center;
  padding: $margin-half;
}

.small-info-text {
  color: $color-label;
  font-size: $font-size-s;
}

.button-container .small-info-text {
  margin-top: $margin-quarter;
}

.tiny-info-text {
  margin-top: $margin-half;
  color: $color-label;
  font-size: $font-size-xs;
  font-weight: 500;
  line-height: 1.17;
}

.name-logo {
  color: $color-logo;
  font-family: $font-default-bold;
  font-size: 20px;
  line-height: 1em;
  white-space: nowrap;

  .logo {
    height: 0.75em;
    vertical-align: baseline;
    padding-right: $margin-quarter;
  }
}
