@import './styles/variables.scss';

.next-retailer {
  .main-container .content-with-header {
    background: $color-light-blue;
    overflow-y: auto;
    .alerts {
      margin: $margin-half $margin-default 0;
    }
  }

  .main-content {
    background: none;
    > .content {
      padding: $margin-default;
    }
  }

  /* Side navigation */
  .navigation {
    .name-logo {
      text-align: left;
      font-size: 32px;
      margin: 0 20px;
      border: none;
    }

    .navigation-link {
      font-family: $font-default;
      font-size: 1.25em;
      cursor: pointer;

      .link-content {
        margin: 0 20px;
        height: 25px;
        padding: 15px 0;
        .icons {
          padding-right: 5px;
        }
      }

      + .navigation-link .link-content {
        border: none;
      }
    }

    hr {
      border: 1px solid rgba(255, 255, 255, 0.75);
      border-top: none;
      margin: $margin-quarter $margin-default;
      &:first-child() {
        margin-top: 0;
      }
    }

    &.mobile-navigation {
      .retailer-menu {
        .info-navigation-links {
          padding: $margin-default $margin-half;
        }
      }
    }
  }

  /* Top navigation */
  .store-navigation {
    box-shadow: none !important;
    font-family: $font-default;
    .store-select {
      .select-with-label {
        padding: $margin-quarter $margin-half;
      }
      .options {
        top: 48px;
        border-top: 0;
      }
      .option,
      input {
        font-family: $font-default;
        color: #000;
      }
      .option:hover {
        background: $color-light-grey;
      }
    }

    .retailer-name {
      font-family: $font-default;
      .label-title {
        display: none;
      }
    }

    .user-navigation {
      box-shadow: none !important;
      .retailer-menu {
        box-shadow: none !important;
        .info-navigation-links {
          border: none;
          color: #000;
          padding: 0;
          .navigation-link {
            font-family: $font-default;
            padding: 1em;
            width: 100%;
            display: inline-block;
            &:hover {
              background-color: $color-lightest-grey;
            }
          }
        }
      }
    }
  }
}
