@import './variables';

$height-range-slider: $margin-default;
$size-slider-button: 2 * $margin-default - $margin-half;

.next-retailer {
  input,
  textarea {
    padding: 5px 10px;
    border: 1px solid $color-dark-grey;
    border-radius: 3px;
  }

  input[type='text'],
  input[type='date'],
  input[type='number'] {
    &:disabled {
      background: $color-light-grey;
      border-color: $color-grey;
    }
    ::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  .slider {
    display: block;
    appearance: none;
    width: 100%;
    height: $height-range-slider;
    background: $color-k-orange;
    padding: 0 $margin-default;
    border: none;
    outline: none;
    margin: 0;
    border-radius: $height-range-slider * 0.5;
    cursor: pointer;

    &::-webkit-slider-thumb {
      appearance: none;
      width: $size-slider-button;
      height: $size-slider-button;
      border-radius: $size-slider-button * 0.5;
      background: white;
      background: url('../../../../images/slider-handle.svg');
      box-shadow: $box-shadow-slider-handle;
      border: none;
    }

    &::-moz-range-thumb {
      width: $size-slider-button;
      height: $size-slider-button;
      border-radius: $size-slider-button * 0.5;
      background: white;
      background: url('../../../../images/slider-handle.svg');
      box-shadow: $box-shadow-slider-handle;
      border: none;
    }

    &::-moz-range-track {
      background: transparent;
    }

    &::-moz-focus-outer {
      border: 0;
    }
  }

  .toggle-button {
    width: 100px;
    span {
      right: 22px;
      top: 6px;
      font-size: $font-size-s;
    }
    &.is-selected:after {
      left: 74px;
    }
    &.is-selected span {
      right: 40px;
    }
  }

  // disable fancy Material UI styling
  .MuiInputBase-input {
    font-family: $font-default;
    height: 1.5rem;
  }
  .MuiInput-underline {
    &:before,
    &:after {
      display: none;
    }
  }
  .MuiFormHelperText-root {
    display: none;
  }

  @media screen and (max-width: $breakpoint-desktop) {
    input,
    textarea,
    select,
    .MuiInputBase-input {
      font-size: 16px !important;
    }
  }
}
