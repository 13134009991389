@import '../../styles/colors';
@import '../../styles/variables';
@import '../../../retailer/next/styles/variables.scss';

.field-editor {
  margin-bottom: 24px;

  &__common {
    padding: $default-vertical-margin $default-horizontal-margin;
  }

  &__props {
    padding: $default-vertical-margin $default-horizontal-margin;
    background-color: $k-ui-grey-02;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $k-ui-grey-30;
      height: 40px;
    }
  }

  .field-details {
    margin: 12px 0 12px 0;

    span {
      display: inline-block;
      margin: 0 8px 12px 0;

      &:not(:last-child) {
        &:after {
          content: '·';
          display: inline-block;
          margin-left: 8px;
          font-size: 24px;
        }
      }
    }
  }

  .lock {
    position: relative;
    top: 26px;
  }

  .content-field-prop {
    &__header {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .delete-field-prop {
        border: none;
        background-color: transparent;
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;
      padding-bottom: 12px;
    }
  }

  .input-number {
    margin-top: 0;
    margin-bottom: 16px;
    label {
      display: block;
      color: $copy-text-color;
      font-family: $font-default;
      font-size: $font-size-xs;
      margin-bottom: 4px;
    }
  }

  .btt-product-image-option-list {
    display: flex;
    flex-wrap: wrap;
    gap: $default-vertical-margin;
    margin-bottom: $default-vertical-margin;

    img {
      box-shadow: $box-shadow;
    }
  }
}
