@import './colors';
@import './utils';
@import './variables';

.editor-header {
  display: block;
  padding: $default-vertical-margin $default-horizontal-margin 0 $default-horizontal-margin;
  @include nav-shadow;
  background-color: white;
}

.editor-header__controls {
  display: flex;
  align-items: center;

  .go-back {
    display: inline-block;
  }

  h4 {
    color: $k-ui-grey-50;
    font-size: 16px;
    font-family: 'SkattaSans-Regular', sans-serif;
  }

  .editor-header__details {
    padding: 0 $default-horizontal-margin 0 $default-horizontal-margin;

    span {
      display: inline-block;
      margin: 0 8px 12px 0;
      font-size: 14px;

      &:not(:last-child) {
        &:after {
          content: '·';
          display: inline-block;
          margin-left: 8px;
          font-size: 24px;
        }
      }
    }
  }

  .editor-header__actions {
    margin-left: auto;

    button {
      border: 2px solid $k-ui-orange;
      color: $k-ui-orange;
      border-radius: $border-radius-normal;
      background-color: white;
      text-transform: uppercase;
      font-family: 'SkattaSans-Bold', sans-serif;
      display: inline-block;
      margin: 0 12px 0 0;
      padding: 4px 12px;
      cursor: pointer;

      &.delete {
        border-color: $status-red;
        background-color: $status-red;
        color: white;

        img {
          width: 18px;
          vertical-align: text-bottom;
          display: inline-block;
          margin: 0 4px 0 0;
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: $k-ui-grey-50;
        background: $k-ui-grey-02;
        border-color: $k-ui-grey-02;
      }
    }
  }
}

.editor-header__tabs {
  display: flex;
  width: 100%;
  max-width: $app-content-max-width;
  margin: 0 auto;
  margin-top: 12px;

  .tab-selector {
    background-color: white;
    border: none;
    font-family: 'SkattaSans-Bold', sans-serif;
    flex: 1;
    padding: 6px 0 6px 0;
    cursor: pointer;

    &.is-selected {
      border-bottom: 2px solid $k-ui-orange;
    }

    &:disabled {
      cursor: default;
    }
  }
}

.editor-section {
  @include block-shadow;
  background-color: white;
  padding: $default-vertical-margin $default-horizontal-margin;
  border-radius: $border-radius-normal;
  margin-bottom: $default-vertical-margin;
  font-size: 14px;

  &__header {
    .title-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &__buttons {
        display: flex;
        align-items: center;
      }
    }

    button {
      @extend .styled-button;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .entity-details {
      margin: 12px 0 12px 0;

      span {
        display: inline-block;
        margin: 0 8px 12px 0;

        &:not(:last-child) {
          &:after {
            content: '·';
            display: inline-block;
            margin-left: 8px;
            font-size: 24px;
          }
        }
      }
    }
  }

  .section-title {
    margin-bottom: 12px;
  }

  &.no-padding {
    padding: 0;

    .section-title {
      padding: $default-vertical-margin $default-horizontal-margin;
      margin-bottom: 0;
    }
  }
}
