@import '../../../../styles/variables';

.next-retailer .delivery-form .delivery-offers {
  .delivery-offer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $breakpoint-desktop) {
      flex-wrap: wrap;
    }
    padding: $margin-default 0;
    > * {
      margin: 0 $margin-half;
      @media screen and (max-width: $breakpoint-desktop) {
        box-sizing: border-box;
        margin: 0;
        padding: 0 $margin-half;
      }
    }

    .offer-image {
      position: relative;
      width: 100px;
      text-align: center;
      img {
        max-width: 100%;
        max-height: 100px;
      }
      @media screen and (max-width: $breakpoint-desktop) {
        max-width: 25%;
        margin: 0;
      }

      svg.plussa-offer {
        position: absolute;
        top: -0.75em;
        left: -0.75em;
        background: $color-k-orange;
        border-radius: 1.25em;
        padding: 5px;
        path {
          fill: #fff;
        }
      }
    }
    .offer-info {
      flex-grow: 1;
      @media screen and (max-width: $breakpoint-desktop) {
        max-width: 75%;
      }
      .info-text {
        display: block;
        color: $color-grey;
        svg {
          height: 1em;
          width: 1em;
          position: relative;
          top: 0.15em;
          path {
            fill: $color-grey;
          }
        }
      }
    }
    .offer-actions {
      text-align: right;
      margin-right: 0;
      .btn {
        display: inline-block;
        margin: $margin-quarter 0 0 $margin-quarter;
      }
      @media screen and (max-width: $breakpoint-desktop) {
        width: 100%;
        text-align: center;
        margin: $margin-default $margin-half 0;
        .btn {
          display: inline-block;
        }
      }
    }
  }
  .delivery-offer + .delivery-offer {
    border-top: 1px solid $color-light-grey;
  }
  .delivery-offers-footer {
    text-align: center;
  }
}
