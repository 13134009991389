@import '../../styles/variables.scss';

.next-retailer .box.template-box {
  display: flex;
  padding: $margin-half $margin-half;
  align-content: center;
  justify-content: space-between;

  > div {
    margin: 0 $margin-half;
  }

  @media screen and (max-width: $breakpoint-desktop) {
    flex-wrap: wrap;
    justify-content: center;
    &.truncated {
      overflow: hidden;
      flex-wrap: nowrap;
    }
  }

  &.truncated {
    font-size: 0.8em;
    padding: $margin-half;
    cursor: pointer;
  }

  .template-header {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    .icon {
      display: flex;
      align-items: center;
      margin-right: $margin-half;
    }
    .basic-info {
      flex-grow: 1;
      h3 {
        margin-bottom: $margin-quarter;
      }
    }
    .clickable {
      cursor: pointer;
    }
  }

  .template-default-offer {
    background: $color-lightest-grey;
    display: flex;
    padding: 0 $margin-half;
    border-radius: $border-radius;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex: 0 0 15em;
    strong {
      font-size: $font-size-s;
    }
    @media screen and (max-width: $breakpoint-desktop) {
      display: none;
    }
  }

  .target-group {
    background: $color-lightest-grey;
    display: flex;
    padding: 0 $margin-half;
    border-radius: $border-radius;
    align-items: center;
    text-align: center;
    @media screen and (max-width: $breakpoint-desktop) {
      display: none;
    }
    .channel {
      min-width: 100px;
    }
  }
  .template-status {
    &:empty {
      display: none;
    }
    display: flex;
    align-items: center;
    text-align: center;
    @media screen and (max-width: $breakpoint-desktop) {
      min-width: 50px;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: $breakpoint-desktop) {
      margin: 0;
      justify-content: center;
      width: 100%;
      margin-top: $margin-half;
    }
  }
  .caret-end {
    display: flex;
    align-items: center;
  }
  .hl {
    font-size: $font-size-l;
    color: $color-k-orange;
    font-family: $font-default-bold;
  }
  ul {
    margin: 0;
    list-style: none;
    padding-left: 0;
  }
  .participation-button {
    margin-left: 2px;
  }
}
