@import '../../styles/variables';
@import '../../styles/utils';
@import '../../styles/colors';

.next .template-editor {
  .section-title {
    margin-bottom: 12px;
  }

  .radio-group {
    display: flex;
    .radio-button {
      flex-basis: 150px;
    }
  }

  .editor-section.channels {
    .channel-selection {
      h4 {
        margin-bottom: 12px;
      }
    }
  }

  .html-editor-container {
    .DraftEditor-root {
      border: 1px solid $k-ui-border-grey;
      border-radius: $border-radius-small;
      padding: 4px 12px;
    }
  }

  .email-content {
    .add-content-template {
      border: none;
      background-color: transparent;
    }
  }

  .missing-fields {
    color: $status-red;
    font-size: 18px;

    span,
    b {
      display: inline-block;
    }

    b {
      margin-left: 8px;
    }
  }

  .existing-content-fields {
    h3 {
      margin: 24px 0 8px 0;
    }

    .block-content-field {
      background-color: $k-ui-background-grey;
      margin-bottom: 8px;
      padding: $default-vertical-margin $default-horizontal-margin;

      h4 {
        margin-bottom: 12p;
      }

      span {
        display: inline-block;
        margin: 0 8px 0 0;

        &:not(:last-child) {
          &:after {
            content: '·';
            display: inline-block;
            margin-left: 8px;
            font-size: 24px;
          }
        }
      }
    }
  }
}
.pricing-group-related {
  font-style: italic;
  margin-left: 5px;
}
.next .preview-pane {
  .drawer-content-inside {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    padding-top: 90px;
    padding-bottom: 40px;
  }

  .preview-options {
    position: relative;
    top: 15px;
    left: 20px;
    z-index: 2;
    display: flex;
    align-items: center;
  }
  .preview-actions {
    display: flex;
    justify-items: center;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
  }
  .preview-additional-options {
    position: relative;
    width: 100%;
    display: flex;
    top: 20px;
    margin-left: 20px;
    z-index: 1;
    .checkbox {
      margin-left: 20px;
    }
  }
  .content-preview {
    height: 100%;
    overflow: auto;
    .preview-image {
      border: 1px solid #f0f0f0;
    }
    .preview-pdf {
      width: 100%;
      height: 100%;
    }
  }

  .email-preview-link {
    margin-left: auto;
  }
  .generate-link {
    display: flex;
    margin-left: auto;
    align-items: center;
    padding: 4px 5px;
  }

  .preview-url-container {
    display: flex;
    align-items: center;
    margin-left: auto;

    .preview-url {
      width: 20rem;
      font-size: 0.675rem;
    }
  }
}
