@import '../../../next/styles/colors';

.next .toggle-button,
.next-retailer .toggle-button {
  height: 30px;
  width: 84px;
  border-radius: 15px;
  display: block;
  border: none;
  position: relative;
  cursor: pointer;

  span {
    font-size: 10px;
    color: $k-ui-grey-75;
    text-transform: uppercase;
    font-family: 'SkattaSans-Bold';
    position: absolute;
    right: 8px;
    top: 8px;
    display: inline-block;
    width: 46px;
    text-align: center;
  }

  &:after {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 16px;
    top: 4px;
    left: 4px;
    background-color: white;
    transition: left 0.1s;
  }

  &:not(.is-selected) {
    background-color: $k-ui-grey-30;
  }
  &.is-selected {
    background-color: $k-ui-orange;
    transition: background-color 0.2s;

    span {
      right: 29px;
      color: white;
    }

    &:after {
      left: 58px;
      transition: left 0.1s;
    }
  }

  &.disabled {
    background-image: repeating-linear-gradient(
      45deg,
      $k-ui-grey-30,
      $k-ui-grey-30 3px,
      transparent 3px,
      transparent 10px
    );
    cursor: default;

    span {
      color: white;
    }
  }
}
