@import '../../../../styles/variables';

.next-retailer .delivery-form .delivery-dates {
  display: flex;
  > div {
    min-width: 50%;
    margin-right: $margin-default;
  }
  .input-container {
    padding-right: $margin-default;
    display: flex;
    flex-wrap: wrap;
    input,
    .btn {
      margin-bottom: $margin-quarter;
    }
    input {
      margin-right: $margin-quarter;
    }
    @media screen and (max-width: $breakpoint-desktop) {
      display: block;
      input,
      .btn {
        width: 100%;
        margin-right: 0;
      }
      .MuiFormControl-root {
        width: 100%;
      }
    }
    .separator {
      line-height: 2em;
      padding: 0 0.5em 0 0.25em;
      @media screen and (max-width: $breakpoint-desktop) {
        display: block;
      }
    }
  }
}
