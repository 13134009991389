@import '../../styles/variables.scss';

.next-retailer .concept-list {
  margin-top: $margin-huge;

  .concept-box {
    display: flex;
    align-items: center;
    > div + div {
      margin-left: $margin-half;
    }
    .basic-info {
      flex-grow: 1;
      h3 {
        margin-bottom: $margin-quarter;
      }
    }
    .caret-left {
      display: flex;
      align-items: center;
    }
    .extra-info {
      display: flex;
      flex-grow: 1;
    }
    .auto-participation {
      text-align: right;
      &.enabled {
        color: $color-success;
      }
      &.available {
        color: $color-k-orange-2;
      }
    }
  }

  .no-results {
    margin-top: $margin-default;
  }
}
