@import '../../../styles/colors';

.next {
  .offer-editor {
    margin-bottom: 15px;
  }
  .offer-option {
    .editor-section__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 -10px;
      > * {
        margin: 0 10px;
      }
      .offer-details {
        display: flex;
        flex-grow: 1;
        height: 100%;
        pre {
          font-family: 'SkattaSans-Regular';
          font-size: 1rem;
          color: $k-ui-grey-75;
        }
      }
      .offer-image {
        display: flex;
        width: 120px;
        justify-content: center;
        img {
          object-fit: contain;
          max-width: 100%;
          max-height: 160px;
        }
      }
    }
  }
}
