@import '../../styles/variables.scss';
@import '../../../../../components/next/styles/colors.scss';

.next-retailer .profile-page {
  section {
    background: #fff;
    border-radius: 5px;
    padding-bottom: $padding-default * 0.5;

    // 1st cell on header rows.
    > div:first-child > div:first-child {
      font-size: 1.25em;
    }

    > div:first-child {
      padding: $padding-default;
      display: flex;
      align-items: center;
    }

    > div > div:first-child {
      width: 150px;
      font-weight: bold;
      vertical-align: top;
    }

    > div {
      padding: $padding-default * 0.5 $padding-default;

      &:nth-child(even) {
        background: $k-ui-grey-02;
      }
      > div {
        display: inline-block;
      }
    }

    a {
      color: $color-k-orange;
    }

    .notification-contacts {
      .notification-contact {
        button {
          background: none;
          border: none;
          padding: 0;
          color: $color-k-orange;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .new-notification-contact {
        margin-top: $margin-default;

        button {
          margin-top: $margin-default * 0.5;
        }
      }
    }
  }

  section + section {
    margin-top: $margin-default;
  }
}
