@import '../../../retailer/next/styles/variables.scss';

.next .offer-form-options,
.next-retailer .offer-form-options {
  display: flex;
  flex-direction: column;
  .offer-options-list {
    padding: 0;
    .offer-option {
      list-style: none;
      padding: 15px;
      display: flex;
      max-height: 120px;
      align-items: center;
      justify-content: space-between;
      .offer-info {
        flex-grow: 1;
        .offer-title {
          font-family: $font-default;
        }
      }
      .offer-image {
        margin-right: 10px;
        min-width: 120px;
        max-height: 100%;
        text-align: center;
        img {
          max-width: 100px;
          max-height: 100px;
          height: auto;
          object-fit: contain;
        }
      }
      .offer-chosen {
        margin-right: 10px;
        color: $color-grey;
      }
    }
    .offer-option + .offer-option {
      border-top: 1px solid #eee;
    }
  }
}
