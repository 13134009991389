@import '../../styles/colors';
@import '../../styles/variables';

.next-header {
  padding: 12px $default-horizontal-margin 12px $default-horizontal-margin;
  display: flex;
  align-items: center;
  color: white;

  a.navigation-link {
    color: white;
    font-family: 'SkattaSans-Regular', sans-serif;

    &.is-active {
      font-weight: 600;
    }
  }

  .name-logo-container {
    display: inline-block;
    margin-right: $default-horizontal-margin;

    .name-logo {
      color: white;
      font-size: $logo-font-size;
      line-height: inherit;
    }

    .logo {
      height: 20px;
      vertical-align: baseline;
    }
  }

  .user-menu {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    > span {
      cursor: pointer;
      padding: 1rem 0;
    }
  }

  .user-dropdown {
    position: absolute;
    top: 55px;
    right: 0;
    background: white;
    width: 16rem;
    display: flex;
    flex-direction: column;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 1rem;
    .dropdown-item {
      text-align: center;
      color: $copy-text-color;
      padding: 1rem 0.5rem;
      cursor: pointer;
      &:hover {
        background: $k-ui-grey-10;
      }
    }
  }
}

/* theme colors */
.next-header {
  &.k-ruoka {
    background-color: $theme-color-kruoka;
  }
  &.k-rauta {
    background-color: $theme-color-krauta;
  }
  &.k-auto {
    background-color: $theme-color-kauto;
  }
  &.onninen {
    background-color: $theme-color-onninen;
  }
}
