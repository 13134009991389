@import '../../../retailer/next/styles/variables.scss';

$size-product-image-small: 50px;

.next-retailer .offer-form-product,
.next-retailer .stampcard-product-picker,
.next-retailer .stampcard-editor,
.next .stampcard-template-editor,
.next .offer-form-product {
  .offer-basics {
    display: flex;
    @media screen and (max-width: $breakpoint-desktop) {
      display: block;
    }
    .product-basics {
      flex-grow: 1;
      margin-left: $margin-half;
      @media screen and (max-width: $breakpoint-desktop) {
        margin-left: 0;
      }

      .detail-text {
        font-size: 13px;
        display: inline-block;
        margin-top: 6px;
        color: $color-grey;
      }
    }
  }

  .regular-price-range {
    .range-identifier {
      padding: 0.5em;
    }
  }

  .product-item {
    display: flex;
    align-items: center;
    padding: $margin-quarter $margin-half;
    border-bottom: $border-divider;

    &:last-child {
      border-bottom: none;
    }

    .image-container {
      margin-right: $margin-half;
      width: $size-product-image-small;
      display: flex;

      img {
        max-height: $size-product-image-small;
        max-width: $size-product-image-small;
        margin: auto;
      }

      .icon {
        width: 20px;
        height: 20px;
      }
    }

    .label {
      font-size: $font-size-xs;
      color: $color-dark-grey;
      white-space: nowrap;
    }

    .title {
      font-family: $font-default-bold;
    }

    .item-details {
      margin: $margin-half 0;
      flex-grow: 1;
    }

    .remove-item {
      height: 100%;
      cursor: pointer;
      padding: 0.5em;
    }

    .price-container {
      text-align: right;

      .on-sale {
        color: $color-cool-grey;
        font-size: $font-size-xs;
      }
    }

    &.disabled {
      cursor: initial;

      > * {
        opacity: 0.5;
      }

      .image-container img {
        opacity: 0.5;
      }

      .title {
        color: $color-light;
      }
    }
  }
}
