@import './variables';
@import './colors.scss';

.next {
  .input-field {
    margin-bottom: $default-vertical-margin;
    vertical-align: top;

    label {
      display: block;
      font-size: 12px;
      margin-bottom: 4px;
    }

    input,
    textarea {
      display: block;
      width: 100%;
      font-size: 14px;
      border-radius: $border-radius-small;
      border: 1px solid $k-ui-border-grey;

      &:read-only {
        background: $k-ui-grey-10;
        border-color: $k-ui-grey-50;
        color: #111;
      }
    }

    textarea {
      padding: 6px 12px;
    }

    input {
      line-height: 36px;
      padding: 0 12px;
    }

    .detail-text {
      font-size: 13px;
      display: inline-block;
      margin-top: 6px;
      color: $k-ui-grey-50;
    }

    .error-text {
      color: $status-red;
      display: inline-block;
      margin-top: 6px;
      margin-right: 24px;
      font-size: 13px;
    }

    &.half-wide {
      display: inline-block;
      width: 50%;

      & + .half-wide:nth-of-type(even) {
        padding-left: $default-horizontal-margin;
      }
    }

    &.third-wide {
      display: inline-block;
      width: calc(100% / 3);
      padding-right: $default-horizontal-margin;

      // & + .input-field.third-wide:nth-of-type(3n + 2),
      // & + .input-field.third-wide:nth-of-type(3n + 3) {
      //   padding-left: $default-horizontal-margin;
      // }
    }

    .MuiInputBase-input {
      height: auto;
      background: white !important;
    }
    .MuiInput-underline {
      &:before,
      &:after {
        display: none;
      }
    }
  }

  .btn {
    border: 2px solid #f86800;
    color: #f86800;
    border-radius: 4px;
    background-color: white;
    text-transform: uppercase;
    font-family: 'SkattaSans-Bold', sans-serif;
    display: inline-block;
    margin: 0 12px 0 0;
    padding: 4px 12px;
    cursor: pointer;

    &:hover {
      cursor: pointer;
    }
  }

  .checkbox input[type='checkbox'] {
    display: none;
  }
}
