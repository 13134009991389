@import '../../styles/variables';
@import '../../styles/colors';

.results-view {
  display: flex;
  flex-direction: column;
}

.next .deliveries {
  display: flex;
  flex: auto;
  overflow: scroll;
  position: relative;
  padding-bottom: 80px;

  header {
    padding: 16px 24px;
  }

  .actions {
    position: fixed;
    bottom: 0;
    background: $k-ui-grey-10;
    width: 100%;
    padding: $sidebar-page-horizontal-padding;
    display: flex;
    align-items: center;

    .summary {
      margin-right: $sidebar-page-horizontal-padding;
    }

    .estimates {
      display: flex;
      align-items: center;
      margin-left: 24px;

      h4 {
        font-size: 18px;
        margin-right: 24px;
      }

      span {
        margin-right: 16px;
        display: flex;
      }

      b {
        margin-right: 12px;
      }
    }
  }

  .delivery-row.archived {
    color: $k-ui-grey-30;
  }

  .failed {
    color: $status-red;
  }
}
