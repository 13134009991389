@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/utils';

.content.reports {
  background-color: white;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $default-vertical-margin;
  }

  .detail-text {
    font-size: 13px;
    display: inline-block;
    margin-top: 6px;
    color: $k-ui-grey-50;
  }

  .report-configuration {
    margin-top: $default-vertical-margin;
  }

  .report-actions {
    margin-top: $default-vertical-margin;
    padding-bottom: $default-vertical-margin;
  }
}
