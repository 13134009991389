@import '../../../next/styles/colors.scss';

.next,
.next-retailer {
  .language-selector {
    display: flex;
    align-items: center;
    margin: -8px 16px 0 0;

    span,
    button {
      display: inline-block;
      padding: 0;
      margin: 0 6px 0 6px;
    }
    button {
      border: none;
      cursor: pointer;
      font-weight: bold;
      background-color: transparent;

      &.is-selected {
        color: $k-ui-orange;
      }
    }
  }
}
