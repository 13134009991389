.header,
.footer,
.mobile-footer {
  .flex-content {
    display: flex;
    align-items: center;
    margin: 0 (-$margin-default);

    > * {
      margin: 0 $margin-default;
    }
  }
}

.header,
.footer {
  background: $background-header;
  z-index: 10;

  .full-page-content {
    padding: $margin-half 0;
  }

  .normal-content {
    padding: $margin-half $margin-double;
  }

  .flex-content {
    .segment-icon {
      flex-basis: $size-header-image;
      height: $size-header-image;
      width: $size-header-image;
      padding-right: $margin-half;
      flex-shrink: 0;
    }
  }
}

.header {
  font-family: $font-default-bold;
  box-shadow: $box-shadow;

  .flex-content {
    justify-content: space-between;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    min-height: $size-header-image;
  }

  .middle {
    text-align: center;
    display: flex;

    @media screen and (max-width: $breakpoint-desktop) {
      display: none;
    }

    .step {
      padding: 0 $margin-half;

      &.with-check {
        text-align: left;
        display: flex;

        img {
          height: $size-header-icon;
          width: $size-header-icon;
          margin: 0.25rem $margin-quarter 0 0;
        }

        .description {
          font-size: $font-size-xs;
          font-family: $font-default-medium;
          margin-top: $margin-mini;
        }

        &.active {
          color: $color-font-emph;
        }
      }
    }
  }

  .right {
    justify-content: flex-end;
    text-align: right;

    &.column {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &.store-navigation {
    z-index: 100;

    .select-with-label {
      padding: $margin-half $margin-double;
    }
  }

  .dynamic-price {
    flex-basis: $min-width-dynamic-price;
    white-space: nowrap;

    .loading {
      height: $size-loading-icon;
      width: $size-loading-icon;
      margin-bottom: $margin-mini;
      animation: spin 1s linear infinite;
    }
  }

  .program-description {
    flex-basis: $basis-header-description;
  }

  .store-name {
    width: 100%;
    padding-bottom: $margin-quarter;
  }
}

.footer {
  box-shadow: $box-shadow-inverse;

  .flex-content {
    justify-content: space-around;
  }

  &.with-space .flex-content {
    min-height: 65px;
  }
}

.mobile-footer {
  .flex-content {
    justify-content: space-around;
    align-items: flex-start;

    @media screen and (max-width: $breakpoint-desktop) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    > * {
      margin-bottom: $margin-default;
    }
  }
}

.footer,
.mobile-footer {
  .button-container {
    text-align: center;
  }

  .button-info-text {
    font-size: $font-size-s;
    margin-top: $margin-mini;
    line-height: 1.2;
    color: $color-label;

    @media screen and (max-width: $breakpoint-desktop) {
      margin-top: $margin-quarter;
    }
  }
}
