@import './colors';
@import './variables';
@import './form-components';
@import './table';

main.next {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  *:focus {
    outline: 0;
  }
  .page {
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
    overflow: auto;
  }
  .app-main-content {
    background-color: $k-ui-background-grey;
    display: flex;
    flex: 1 1 auto;
    min-height: 0;
    min-width: 0;
  }
  .env-banner {
    background-color: $status-red;
    text-align: center;
    color: white;
    font-size: 22px;
    padding: 4px 8px 4px 8px;
    line-height: 2;

    span {
      display: block;
    }

    .ie-warn {
      font-size: 16px;
    }
  }

  .navigation-bar.page-selector {
    display: block;
    @include nav-shadow;
    background-color: white;
    position: relative;
    z-index: 30;

    .navigation-link {
      color: $copy-text-color;
      padding: 12px 16px 12px 16px;
      font-size: 16px;
      margin-right: 16px;

      &.is-active {
        border-bottom: 2px solid $k-ui-orange;
      }
    }
  }
}
