@import '../../styles/variables.scss';

.next-retailer .info-popover {
  .popover-pos-wrapper {
    position: relative;
  }

  .info-button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .popover {
    position: absolute;
    box-shadow: $box-shadow;
    background: white;
    z-index: 1;
    padding: $margin-default;
    box-sizing: border-box;
    margin-bottom: $margin-default;

    .popover-content {
      z-index: 1;
      position: relative;
      width: 100%;
      min-width: 320px;
      min-height: 220px;
      padding-bottom: 38px;
      box-sizing: border-box;

      h1 {
        font-family: $font-default-bold;
        color: $color-k-orange;
        font-size: $font-size-xl;
      }
      h2 {
        font-family: $font-default-bold;
        color: $color-k-orange;
        font-size: $font-size-l;
      }
      h3 {
        font-family: $font-default-bold;
        font-size: $font-size-d;
      }
      h4 {
        font-family: $font-default-bold;
        font-size: $font-size-m;
      }

      a {
        color: $color-k-orange;
      }

      footer {
        position: absolute;
        bottom: -$margin-default;
        padding: $margin-half 0;
        border-top: 1px solid $color-light-grey;
        width: 100%;
        box-sizing: border-box;
        a {
          font-family: $font-default-bold;
        }
      }
    }
  }
  &.right .popover {
    left: 0;
  }
  &.left .popover {
    right: 0;
  }
  &.top .popover {
    bottom: 0;
  }
  &.bottom .popover {
    top: 0;
  }

  @media screen and (max-width: $breakpoint-desktop) {
    .popover {
      width: 100vw;
      right: unset;
      /* we use an ugly javascript hack here to center content after render */
      left: 0;
      opacity: 0;
      .popover-content {
        min-width: 100%;
      }
    }
  }
}
