@import '../../styles/variables';

.next-retailer .delivery-view {
  .content {
    padding-bottom: 260px;
  }

  h2 {
    font-size: $font-size-xl;
    font-family: $font-default;
    &:not(:first-child) {
      margin-top: $margin-double;
    }
  }

  .section-header {
    position: relative;
    > .info-popover {
      position: absolute;
      right: 0;
      top: 0;
      @media screen and (max-width: $breakpoint-desktop) {
        top: -5px;
      }
    }
  }

  .delivery-header {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: $font-size-xxl;
      font-family: $font-default-bold;
      width: 100%;
    }
    .delivery-info {
      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
      }
    }
    .delete-draft-button {
      text-align: right;
      align-self: center;
    }
  }

  .results.box {
    display: flex;
    margin: 0 0 $margin-default;
    @media screen and (max-width: $breakpoint-desktop) {
      flex-wrap: wrap;
    }
    .box-section {
      flex-basis: 0;
      flex-grow: 1;
      padding: $margin-half;
      position: relative;
      .info-popover {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .box-section + .box-section {
      border-left: 1px solid $color-light-grey;
      @media screen and (max-width: $breakpoint-desktop) {
        border: none;
      }
    }
  }

  .editable-notice {
    margin-bottom: $margin-default;
    z-index: 1000;
    .alert-message {
      width: 100%;
      display: flex;
      align-items: center;
      .notice-text {
        flex-grow: 1;
      }
    }
  }

  .channel-box {
    .channel-title {
      margin-bottom: $margin-quarter;
      .channel-count {
        color: $color-k-orange;
      }
    }
    .channel-subtitle {
      margin-bottom: $margin-half;
      color: #ccc;
    }
    .box-sections {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      > .box-section {
        box-sizing: border-box;
        padding: 10px;
        flex-basis: 0;
        flex-grow: 1;
      }
    }
    .channel-preview {
      min-width: 220px;
      max-width: 260px;
      height: 100%;
      text-align: center;
      a {
        display: block;
        width: 100%;
      }
      .preview-wrapper {
        width: 100%;
        max-height: 200px;
        overflow: hidden;
        box-shadow: $box-shadow;
        img {
          max-width: 100%;
        }
      }
      position: relative;
      .preview-icon {
        position: absolute;
        top: -0.75em;
        right: -0.75em;
        background: $color-k-orange;
        border-radius: 1.25em;
        width: 2.5em;
        height: 2.5em;
        svg {
          position: relative;
          top: 0.5em;
          left: 0.1em;
          path {
            fill: white;
          }
        }
      }
      &.has-thumb {
        a {
          align-self: start;
          width: 100%;
        }
        box-shadow: $box-shadow;
      }
      @media screen and (max-width: $breakpoint-desktop) {
        max-width: 100%;
        .preview-icon svg {
          top: 0.3em;
        }
      }
    }
  }

  .channel-box + .channel-box {
    margin-top: $margin-default;
    padding-top: $margin-default;
    border-top: 1px solid $color-light-grey;
  }

  .offer-box {
    .offer-title {
      margin-bottom: $margin-quarter;
    }
    .offer-subtitle {
      margin-bottom: $margin-half;
      color: #ccc;
    }
    .offer-subtitle-link {
      margin-bottom: $margin-half;
      color: $color-dark-blue;
      &:hover {
        text-decoration: underline;
      }
    }
    .offer-eancode {
      margin-bottom: $margin-half;
    }
    .box-sections {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      > .box-section {
        box-sizing: border-box;
        padding: 10px;
        flex-basis: 0;
        flex-grow: 1;
      }
      .offer-image {
        padding: $margin-half;
        text-align: left;
        flex-shrink: 0;
        flex-grow: 1;
        @media screen and (max-width: $breakpoint-desktop) {
          text-align: center;
        }
        img {
          max-width: 160px;
          max-height: 160px;
          height: auto;
          object-fit: contain;
        }
      }
      .offer-info {
        flex-grow: 1;
        .recommended {
          display: block;
          color: $color-grey;
          svg {
            height: 1em;
            width: 1em;
            position: relative;
            top: 0.15em;
            path {
              fill: $color-grey;
            }
          }
        }
      }
    }
  }

  .offer-box + .offer-box {
    margin-top: $margin-default;
  }

  .channel-box .box-sections,
  .offer-box .box-sections {
    .box-section .result {
      min-width: 160px;
      min-height: 100%;
      flex-grow: 0;

      padding: $margin-half;
      box-sizing: border-box;
      background: $color-lightest-grey;
      border-radius: $border-radius;

      font-size: 0.8em;
      line-height: 1.1;
      p {
        font-size: inherit;
        line-height: inherit;
      }

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: center;

      h4 {
        text-transform: uppercase;
        color: #999;
        font-size: 0.8rem;
        margin-bottom: $margin-half;
      }
      .number-display {
        color: $color-k-orange;
        font-family: $font-default-bold;
        font-size: 1.4rem;
        margin-top: $margin-quarter;
        margin-bottom: $margin-quarter;
      }

      .hidden {
        display: none;
      }

      &.no-results {
        justify-content: center;
      }
    }
    margin: -10px;
    @media screen and (max-width: $breakpoint-desktop) {
      margin: 0;
      .box-section {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 100%;
        font-size: 1.2em;
        .result {
          min-height: 4rem;
        }
      }
    }
  }
}
