@import '../../../retailer/next/styles/variables.scss';

.input-image {
  position: relative;
  text-transform: uppercase;
  color: $color-k-orange;
  font-family: $font-default-bold;
  text-align: center;

  svg {
    position: relative;
    top: 4px;
  }

  .image-upload {
    border: 1px solid $color-grey;
    border-radius: 3px;
    background: white;
    position: relative;
    min-width: 260px;
    .image-preview {
      max-width: 100%;
      box-sizing: border-box;
      object-fit: contain;
    }
    .image-input {
      cursor: pointer;
      padding: 0;
      opacity: 0.0001;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .image-loader {
    text-align: initial;
    border: 1px solid $color-grey;
    border-radius: 3px;
    background: white;
    min-width: 260px;
  }

  .image-text {
    text-align: left;
    text-transform: none;
  }

  .select-prompt,
  .disabled-prompt {
    text-align: initial;
    width: 100%;
    font-size: 2em;
    svg {
      display: inline-block;
      position: relative;
      top: calc(50% - 21px);
      left: calc(50% - 21px);
      width: 42px;
      height: 42px;
    }
  }

  .disabled-prompt {
    svg path {
      fill: $color-cool-grey;
    }
  }

  .image-actions {
    display: flex;
    flex-direction: row;
    .upload-image {
      flex: 1 1 auto;
      cursor: pointer;
      &.disabled {
        cursor: default;
      }
    }
    .remove-image {
      flex: 1;
      padding-top: 0.5rem;
      cursor: pointer;
      &.disabled {
        cursor: default;
      }
    }

    .custom-dropdown {
      flex: 1;
      padding-top: 0.5rem;
      font-family: $font-default;
      color: #111;
      cursor: pointer;
      &.disabled {
        cursor: default;
      }
    }

    ul {
      list-style: none;
      text-align: left;
    }
  }
}
.error-text {
  color: $color-red;
}
