@import 'fonts';

// Breakpoints
$breakpoint-phone: 480px;
$breakpoint-desktop: 767px;

// Fonts
$font-default: 'SkattaSans-Regular', sans-serif;
$font-default-bold: 'SkattaSans-Bold', sans-serif;
$font-default-medium: 'SkattaSans-Medium', sans-serif;

// Font sizes
$font-size-base: 16px;
$font-size-xxs: 0.675em;
$font-size-xs: 0.75rem;
$font-size-s: 0.875rem;
$font-size-m: 0.9375rem;
$font-size-l: 1.25rem;
$font-size-xl: 1.5rem;
$font-size-xxl: 1.75rem;
$font-size-xxxl: 2rem;
$font-size-xxxxl: 2.25rem;
$font-size-huge: 2.875rem;

// Margins and padding
$margin-default: 20px;
$margin-half: $margin-default * 0.5;
$margin-quarter: $margin-default * 0.25;
$margin-double: $margin-default * 2;
$margin-mini: 2px;
$margin-smaller: 0.75 * $margin-default;
$margin-bigger: 1.5 * $margin-default;
$margin-huge: $margin-default * 3;

// Color codes
$color-k-orange: #ff6900;
$color-k-orange-2: #ff8f40;
$color-dark-blue: #00205b;
$color-white: #ffffff;
$color-blue: #0c4275;
$color-cool-grey: #9ea2a2;
$color-light-grey: #e5e5e5;
$color-lightest-grey: #f5f5f5;
$color-dark-grey: #25282a;
$color-grey: #707372;
$color-middle-grey: #999;
$color-grape-purple: #651d32;
$color-light-blue: #ebf3f4;
$color-lighter-blue: #f3f8f8;
$color-rouge: #9b2242;
$color-green: #8fd641;
$color-darkest-blue: #000142;
$color-light-orange: #fff7f2;
$color-orange: #ffaa33;
$color-green-2: #a2e558;
$color-teal: #27abc2;
$color-transparent: rgba(255, 255, 255, 0);
