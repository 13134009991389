@import '../../styles/editor';
@import '../../styles/colors';
@import '../../styles/variables';

.next .content-block {
  background-color: $k-ui-background-grey;
  flex: 1 1 auto;
  header {
    .editor-status {
      display: inline-block;

      &.ok {
        color: $status-green;

        svg > path {
          fill: $status-green;
        }
      }
      &.warning {
        color: $k-ui-orange;

        svg > path {
          fill: $k-ui-orange;
        }
      }
      &.error {
        color: $status-red;

        svg > path {
          fill: $status-red;
        }
      }

      > span {
        display: flex;
        align-items: center;
        margin-right: $default-vertical-margin;

        svg {
          width: 36px;
          height: 36px;
          margin-left: 8px;
        }
      }
    }
  }
}
