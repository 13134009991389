@import './colors';
@import './variables.scss';

/*
  Utility style classes. Placed here for want of a better place
*/

.next {
  .results-container {
    width: 100%;
    display: flex;
    background-color: white;
    padding: 16px 0;
    line-height: 1;

    .result {
      text-align: center;
      flex: 1;

      span {
        display: block;
      }

      &__value {
        font-family: 'SkattaSans-Bold';
        font-size: 30px;
        margin-bottom: 4px;
      }
    }
  }

  /* the little colored blobs that contain chain name abbreviations */
  .chain-name {
    display: inline-block;
    font-size: 12px;
    border-radius: 16px;
    line-height: 12px;
    color: white;
    background-color: $theme-color-krauta;
    white-space: nowrap;
    padding: 8px 12px;
    margin: 0 6px 6px 0;
    font-family: 'SkattaSans-bold', sans-serif;

    &.KCM {
      background-color: $theme-color-kcm;
    }

    &.KM {
      background-color: $theme-color-km;
    }

    &.NK {
      background-color: $theme-color-nk;
    }

    &.KSM {
      background-color: $theme-color-ksm;
    }

    &.KR {
      background-color: $theme-color-krauta;
    }

    &.OE {
      background-color: $theme-color-onninen;
    }
  }

  /* back button (found on e.g. template editor page and error page)*/
  .go-back {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      margin-right: 12px;
    }
  }

  .error-message {
    h3 {
      margin-bottom: 12px;
    }

    h4 {
      margin-bottom: 24px;
    }
  }

  /* "entity grid" for templates/programs/what have you, stuff that's displayed on sidebar-enhanced list pages*/
  .entity-grid {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    white-space: nowrap;

    th,
    td {
      padding: 0 $sidebar-page-horizontal-padding;
      border-right: 2px solid $k-ui-background-grey;
      border-bottom: 2px solid $k-ui-background-grey;

      &:first-child {
        border-left: 2px solid $k-ui-background-grey;
      }

      &:nth-child(2) {
        width: 250px;
      }
    }

    td {
      line-height: 36px;

      &.long {
        white-space: unset;
      }
    }

    a {
      text-align: center;
    }

    img {
      vertical-align: middle;
    }

    thead {
      text-align: left;
      font-weight: 600;

      th {
        background-color: $k-ui-background-grey;
        line-height: 48px;
      }
    }
  }

  .styled-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }

  .delete-button,
  .add-button {
    @extend .styled-button;

    display: flex;
    align-items: center;
    // margin-bottom: 16px;
    font-size: 1rem;

    img {
      margin-left: 12px;
    }
  }

  .delete-button:disabled svg path {
    fill: $k-ui-grey-50;
  }

  .search-box {
    &__input {
      width: 100%;
    }
  }
}
