@import '../../../retailer/next/styles/variables.scss';

.next-retailer .offer-form-basket,
.next .offer-form-basket {
  .image-upload {
    width: 200px;
    margin-bottom: $margin-half;
  }
  .form-section.first {
    display: flex;
    @media screen and (max-width: $breakpoint-desktop) {
      display: block;
    }
    .basket-opts {
      flex-grow: 1;
      margin-left: $margin-default;
      @media screen and (max-width: $breakpoint-desktop) {
        margin-left: 0;
      }
    }
  }

  .select-type {
    margin-bottom: $margin-half;
  }

  .error {
    color: $color-red;
  }
}
