@import '../../styles/variables';

.next-retailer .delivery-target {
  .box-sections {
    display: flex;
    @media screen and (max-width: $breakpoint-desktop) {
      display: flex;
      flex-direction: column;
    }
  }
  .box-section {
    flex: 1;
    h4 {
      color: $color-cool-grey;
      text-transform: uppercase;
      font-size: $font-size-s;
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
  }

  .box-section.actions {
    display: flex;
    align-items: center;
    a,
    .btn {
      display: block;
      width: 100%;
    }
  }
}
