@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/utils';

.stampcard-template-editor {
  flex: 1 1 auto;
  .editor-header {
    .editor-header__controls {
      .go-back {
        display: flex;
      }

      .editor-header__actions {
        display: flex;
        align-items: center;

        .save {
          margin-left: $default-vertical-margin;
        }

        .editor-status {
          display: inline-block;

          &.ok {
            color: $status-green;

            svg > path {
              fill: $status-green;
            }
          }
          &.warning {
            color: $k-ui-orange;

            svg > path {
              fill: $k-ui-orange;
            }
          }
          &.error {
            color: $status-red;

            svg > path {
              fill: $status-red;
            }
          }

          > span {
            display: flex;
            align-items: center;
            margin-right: $default-vertical-margin;

            svg {
              width: 36px;
              height: 36px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .content {
    .editor-section {
      .section-content {
        &.split {
          display: flex;

          &.grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: $default-horizontal-margin;
          }
        }
      }
    }

    .input-field {
      margin: 0;
    }

    .stampcard-template-image-preview-html {
      margin-bottom: $default-vertical-margin;
    }

    .stampcard-template-image-backgrounds {
      margin-left: $half-horizontal-margin;

      .stampcard-template-image-background-selection {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        .stampcard-image-image-background {
          display: flex;

          .radio-button label {
            display: flex;
            align-items: center;
          }

          img {
            max-width: 80%;
          }
        }
      }
    }

    .stampcard-template-add-product-inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: $default-horizontal-margin;
      margin-bottom: $half-vertical-margin;
      position: relative;
    }

    .stampcard-template-add-background-image {
      margin-bottom: $half-vertical-margin;
    }

    .stampcard-template-add-product-actions,
    .stampcard-template-add-background-actions {
      text-align: right;

      .btn {
        margin: 0;
      }
    }

    .stampcard-template-editor-products,
    .stampcard-template-editor-rewards {
      .product-picker-product {
        display: flex;
        align-items: center;

        & + .product-picker-product {
          margin-top: $half-vertical-margin;
        }

        .product-image {
          display: flex;
          max-width: 50px;
          max-height: 50px;
          justify-content: center;

          > img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .product-details {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin: 0 $half-vertical-margin;

          .product-ean {
            color: $k-ui-grey-50;
            font-size: 0.875rem;
          }
        }

        .product-pricing {
          display: flex;
          flex-direction: column;

          .product-price {
            text-align: right;
            &.discount {
              font-weight: bold;
              color: $k-ui-orange;
            }
          }

          .product-pricingUnit {
            color: $k-ui-grey-50;
            font-size: 0.875rem;
          }
        }

        .remove-item {
          margin-left: $half-horizontal-margin;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .reward-details {
      img {
        max-width: 100px;
        max-height: 100px;
        display: block;
        margin: auto;
        margin-top: $default-vertical-margin;
      }
    }

    .stampcard-template-editor-rewards {
      .stampcard-template-editor-rewards-actions {
        margin-top: $default-vertical-margin;
      }
    }
  }

  .stampcard-background-image-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 24px;

    div,
    label {
      cursor: pointer;
    }
  }
}
