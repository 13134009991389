@import '../../../styles/variables';
@import '../../../../../next/styles/colors.scss';

.next-retailer {
  .delivery-form {
    .header {
      .form-title {
        margin-top: $margin-half;
        font-size: $font-size-l;
      }
      .header-navigation {
        min-height: 45px;
      }
      .close-button {
        top: 15px;
        right: 15px;
      }
    }

    .main-content {
      .alerts {
        margin-bottom: $margin-default;
      }

      h2,
      h3 {
        margin-bottom: $margin-half;
      }
      h2 {
        font-family: $font-default;
        font-size: $font-size-l;
      }

      .section-header {
        position: relative;
        > .info-popover {
          position: absolute;
          right: 0;
          top: 0;
          @media screen and (max-width: $breakpoint-desktop) {
            top: -5px;
          }
        }
      }

      .description {
        color: $k-ui-grey-50;
        font-size: 14px;
        margin-bottom: $margin-half;
        margin-left: $margin-default;
      }
    }
    .footer {
      text-align: center;
      .btn,
      a {
        display: inline-block;
      }
      .btn {
        margin: 12px 6px;
      }
      .warning {
        display: inline-block;
        color: $k-ui-orange;
      }
    }

    .popover {
      .popover-content footer a {
        display: block;
        position: relative;
        svg {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .language-selection {
    display: flex;

    .language-name {
      color: $color-cool-grey;
      text-transform: uppercase;
      font-size: $font-size-s;
      margin-bottom: 14px;
      font-family: $font-default-bold;
      display: inline-block;
    }

    > div {
      width: 200px;
    }
  }

  .mobile-footer {
    margin-bottom: $margin-half;
    .footer-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: stretch;
      .btn {
        &.btn-bordered {
          background: white;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 $margin-quarter $margin-half;
        flex-grow: 1;
        flex-basis: 0;
        min-height: 5em;
        text-align: center;
      }
    }
  }
}
