@import '../../styles/variables.scss';

.next-retailer .help-page {
  h1 {
    font-size: $font-size-xxl;
    /*font-family: $font-default-black;*/
  }

  h2 {
    font-size: $font-size-xl;
    font-family: $font-default;
  }

  img {
    max-width: 100%;
  }

  section {
    max-width: 800px;
  }

  section + section {
    margin-top: $margin-default;
  }

  .table-of-contents {
    font-size: $font-default;
  }
}
