.mobile-header {
  .mobile-header-content {
    text-align: center;
    padding: 0 $size-menu-button;
    position: relative;
    height: $size-menu-button;

    .menu-button {
      height: 100%;
      width: $size-menu-button;
      box-sizing: border-box;
      padding: $margin-smaller;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
    }

    .name-logo-container {
      display: inline-block;

      .name-logo {
        padding: $margin-smaller;
      }
    }
  }
}
