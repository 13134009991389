@import '../../../../styles/variables.scss';

.next-retailer .content-form {
  .content-form-footer {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    .btn + .btn {
      margin-left: $margin-quarter;
    }
  }

  .languages {
    display: flex;
    align-items: center;
    background: $color-lightest-grey;
    padding: 15px;
    margin-bottom: $margin-default;

    .language-selector {
      margin: 0 16px 0 16px;
    }

    .detail-text {
      margin-top: 0;

      svg {
        height: 1em;
        width: 1em;
        position: relative;
        top: 0.15em;
        path {
          fill: $color-grey;
        }
      }
    }
  }

  .content-template-select {
    background: $color-lightest-grey;
    padding: 24px 15px 15px 15px;
    margin-bottom: $margin-default;
    position: relative;

    h3 {
      display: inline-block;
    }

    .info-popover {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .slot {
    background: $color-lightest-grey;
    padding: 15px;
    margin-bottom: $margin-default;

    h3 {
      display: inline-block;
    }

    .info-popover {
      float: right;
    }
  }

  .block {
    margin-top: $margin-half;
  }

  .form-control {
    input,
    select,
    textarea {
      background: white;
      border: 1px solid $color-grey;
      border-radius: 3px;
      width: 100%;
      font-family: $font-default;
      font-size: 1em;
      display: block;
      padding: 6px 12px;
    }
    label {
      display: block;
      font-family: $font-default-bold;
      font-size: $font-size-s;
      color: $color-grey;
      margin-bottom: 2px;
    }
    select {
      height: 2.2em;
    }
    input[type='radio'] {
      display: none;
    }
    textarea {
      min-height: 10rem;
    }
    .find-product-label {
      color: $color-k-orange;
      text-transform: uppercase;
      font-size: $font-size-m;
    }
  }

  .input-container {
    position: relative;
    .input-suffix {
      position: absolute;
      right: 6px;
      top: 6px;
      color: $color-grey;
    }
  }

  .form-control + .form-control {
    margin-top: $margin-half;
  }

  .form-section + .form-section {
    margin-top: $margin-default;
    border-top: 1px solid $color-light-grey;
    padding-top: $margin-default;
  }

  @media screen and (max-width: $breakpoint-desktop) {
    .radio-button {
      margin-top: $margin-quarter;
      margin-bottom: $margin-quarter;
    }
  }

  .char-count {
    display: inline-block;
    margin-top: 8px;
  }

  .popover {
    footer > a {
      display: flex;
    }
  }

  .btt-product-image-option-list {
    display: flex;
    flex-wrap: wrap;
    gap: $default-vertical-margin;
    margin-bottom: $default-vertical-margin;

    img {
      box-shadow: $box-shadow;
    }
  }
}
