@import '../../styles/variables.scss';
@import '../../../../../components/next/styles/colors.scss';

.next-retailer {
  .push-instructions {
    margin-bottom: 24px;
  }
  .push-notifications {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 24px;

    .btn-bordered {
      background-color: white;
    }
  }

  .push-notification-wrapper {
    flex: 0 1 300px;
    text-align: center;
    margin-bottom: 16px;
  }

  .push-notification {
    background-color: white;
    padding: 24px;
    box-sizing: border-box;
    height: 320px;
    width: 284px;
    position: relative;
    margin: 0 16px 16px 0;
    font-size: 14px;
    text-align: left;

    &__upper-half {
      height: 130px;
      overflow-y: auto;
    }
    &__lower-half {
      height: 130px;
    }

    &__timestamp {
      text-transform: uppercase;
      color: $k-blue;
      display: inline-block;
      margin-bottom: 20px;
    }
    &__valid-until {
      border-top: 1px solid $k-ui-border-grey;
      width: 100%;
      padding: 6px 0;
    }

    &__message {
      display: block;
      margin-bottom: 10px;
    }

    &__additional-message {
      display: block;
      margin-bottom: 20px;
      white-space: pre-wrap;
    }

    &__view {
      width: 100%;
      margin-bottom: 6px;

      h4 {
        font-size: 14px;
        border-top: 1px solid $k-ui-border-grey;
        text-transform: uppercase;
        padding: 6px 0;
      }
    }
  }
}
