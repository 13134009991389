@import '../../styles/variables.scss';

.next-retailer .stampcard-product-picker {
  .drawer-footer {
    padding: $padding-default;
    display: flex;
    justify-content: flex-end;

    * + * {
      margin-left: $margin-default;
    }
  }

  h2 {
    font-family: $font-default;
    font-size: $font-size-l;
  }

  .stampcard-product-picker-products {
    margin-bottom: $margin-default;
  }

  .detail-text {
    font-size: $font-size-xs;
    display: inline-block;
    color: $color-grey;
  }

  .product-select {
    margin-bottom: $margin-default;
  }

  .stampcard-product-picker-add-product {
    .stampcard-product-picker-add-product-actions {
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .stampcard-product-picker-add-product-inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: $margin-default;
      margin-bottom: $margin-half;

      .input-image {
        .image-upload img {
          max-height: 100px;
          min-height: 0;
        }
      }
    }
  }
}
