/* General color definitions */
$copy-text-color: #25282a;

/* Note: NOT the same shade as K's brand orange; this is intentional due to accessibility reasons */
$k-ui-orange: #f86800;
$k-ui-orange-20: #ffc486;
$k-ui-orange-30: #ffa754;
$k-blue: #00205b;
$k-black: #25282a;

$k-ui-grey-02: #f7f8f8;
$k-ui-grey-10: #e2e3e3;
$k-ui-grey-20: #c6c7c6;
$k-ui-grey-30: #abacac;
$k-ui-grey-50: #737675;
$k-ui-grey-75: #444;

$k-ui-background-grey: $k-ui-grey-02;
$k-ui-border-grey: $k-ui-grey-50;
$k-ui-table-grey: #f3f3f3;

/* Themes for K chains*/
$theme-color-krauta: #330072;
$theme-color-kauto: $k-black;
$theme-color-kruoka: $k-blue;
$theme-color-onninen: #002855;

$theme-color-kcm: #c00d1e;
$theme-color-ksm: #002855;
$theme-color-km: #9b2242;
$theme-color-nk: #003c96;

/* alerts & messages */
$status-red: #d1152c;
$status-green: #5cbf00;
