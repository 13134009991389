@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/utils';

.content.stampcard-templates {
  padding: $default-vertical-margin 0;
  background-color: white;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $default-vertical-margin;

    a {
      display: flex;

      img {
        margin-left: $half-horizontal-margin;
      }
    }
  }
}
