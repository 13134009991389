@import '../../styles/colors';

.store-selector {
  &__wrapper {
    display: block;
    position: relative;
  }
  &__list {
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    position: absolute;
    overflow: auto;
    display: block;
    top: 38;
    left: 0;
    right: 0;
    z-index: 100000;
    &--hidden {
      display: none;
    }
  }
  &__store {
    cursor: pointer;
    padding: 6px 12px;

    &:first-child {
      padding-top: 12px;
    }

    &:last-child {
      padding-bottom: 12px;
    }

    &--highlight {
      background-color: #eee;
    }
  }

  .selected {
    padding: 6px 4px;
    font-size: 14px;
    color: #737675;

    &__store {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__remove {
      border: none;
      background: none;
      display: flex;
      padding: 0;
      font-size: 14px;
      color: #737675;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
