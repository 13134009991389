.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main-container {
  display: flex;
  height: 100%;
  overflow: hidden;

  .content-with-header {
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .content-overlay {
    width: 100vw;
    flex-shrink: 0;
    z-index: 1000;
    margin-top: $size-menu-button;
    background: $background-overlay;

    + .content-with-header {
      flex-shrink: 0;
      width: 100vw;
      margin-left: -100vw;
    }
  }
}

.main-content {
  background-color: $color-light-blue;
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  .content {
    padding: $margin-double;

    @media screen and (max-width: $breakpoint-desktop) {
      padding: $margin-half;
    }
  }

  .full-page-content {
    padding: $margin-double 0;

    @media screen and (max-width: $breakpoint-desktop) {
      padding: $margin-half 0;
    }
  }

  .page-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .page-title {
    margin-bottom: $margin-default;
    display: flex;
    justify-content: space-between;
  }

  .box-title,
  .segment-title,
  .box-text {
    margin-bottom: $margin-default;
  }

  .box-content {
    padding: $margin-double;

    @media screen and (max-width: $breakpoint-desktop) {
      padding: $margin-default $margin-smaller;
    }
  }

  .expand-button-container {
    padding: $margin-half;

    .expand-button {
      color: $color-font-emph;
      text-align: center;
      padding: $margin-default;
      cursor: pointer;

      img {
        width: $size-info-icon;
        height: $size-info-icon;
        margin-left: $margin-half;
        vertical-align: sub;

        @media screen and (max-width: $breakpoint-desktop) {
          width: $size-info-icon-mobile;
          height: $size-info-icon-mobile;
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: pointer;
      }
    }

    &.close img {
      transform: scaleY(-1);
    }
  }

  .content-subtitle {
    margin-bottom: $margin-half;
    padding-top: $margin-default;
    margin-top: -$margin-default;

    &.emph {
      color: $color-font-emph;
    }
  }
}

.full-page {
  flex-direction: column;

  .full-page-content {
    width: 90%;
    max-width: $max-width-full-content;
    margin: 0 auto;

    @media screen and (max-width: $breakpoint-desktop) {
      width: calc(100% - 2 * #{$margin-half});
      margin: 0 $margin-half;
    }

    &.no-boxes {
      padding: 0;
      height: 100%;

      .no-boxes-content {
        background: $background-box;
        min-height: 100%;
        box-sizing: border-box;
        position: relative;
      }

      .content {
        .box-title {
          margin-bottom: $margin-double;
          padding-right: $size-close-button;
        }

        @media screen and (max-width: $breakpoint-desktop) {
          padding: $margin-default $margin-smaller;

          .box-title {
            margin-bottom: $margin-default;
            padding-right: $size-close-button - $margin-half;
          }
        }
      }
    }
  }
}

.box-title,
.segment-title {
  display: flex;
  align-items: center;

  .segment-icon {
    flex: 0 0 $size-segment-icon;
    height: $size-segment-icon;
    width: $size-segment-icon;
    padding-right: $margin-default;

    @media screen and (max-width: $breakpoint-desktop) {
      padding-right: $margin-smaller;
    }

    &.big {
      flex-basis: $size-segment-icon-big;
      height: $size-segment-icon-big;
    }
  }
}

.box {
  position: relative;

  .box-tag {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    text-transform: uppercase;
    font-size: $font-size-xxs;
    font-family: $font-default-bold;
    padding: $margin-quarter $margin-half $margin-quarter - $margin-mini;

    &.participating {
      background: $background-tag-participating;
      color: $color-slight-emph;
    }

    &.recommended {
      background: $background-tag-recommended;
      color: $color-font-inverse;
    }

    &.draft {
      background: $background-tag-draft;
      color: $color-font-inverse;
    }

    &.new {
      background: $background-tag-new;
      color: $color-font-inverse;
    }

    &.passed {
      background: $background-tag-passed;
      color: $color-slight-emph;
    }

    &.type-tag {
      background: $background-tag-type;
      color: $color-font-inverse;
    }

    &.upcoming {
      background: $background-tag-passed;
      color: $color-font-inverse;
    }

    + .box-left .box-content {
      @media screen and (max-width: $breakpoint-desktop) {
        padding-top: $margin-bigger;
      }
    }
  }

  .more-info-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    .button {
      margin: $margin-half $margin-default;
      width: 120px;
    }
  }

  .sections {
    display: flex;
    flex-wrap: wrap;
    margin: -$margin-default (-$margin-bigger);

    @media screen and (max-width: $breakpoint-desktop) {
      margin: -$margin-smaller;
    }

    .section {
      margin: $margin-default $margin-bigger;

      @media screen and (max-width: $breakpoint-desktop) {
        margin: $margin-smaller;
      }

      &.text {
        flex: 1 1 $basis-box-text;
        align-self: stretch;
      }

      &.steps {
        flex: 1 1 $basis-box-steps;

        h3 {
          margin-top: 0.45rem;
        }
      }

      &.links {
        align-self: center;
        flex: 0.2 1 200px;
      }

      &.buttons {
        flex: 1 0 200px;
        margin: 0;
        align-self: center;

        .more-info-buttons {
          justify-content: flex-start;
          padding: $margin-half;
        }
      }

      .additional-description {
        margin-top: $margin-half;
      }
    }
  }
}

.divider {
  border-top: $border-divider;
}

.box-divided {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex-grow: 1;
    text-align: center;
  }

  &.equal > div {
    flex-basis: 10px;
  }
}

.steps-preview {
  padding-top: $margin-default;

  @media screen and (max-width: $breakpoint-desktop) {
    padding-top: $margin-smaller;
  }

  .step {
    display: flex;
    align-items: center;
    padding-bottom: $margin-half;

    .step-icon {
      padding-right: $margin-default;
      flex: 0 0 $size-step-icon;
      width: $size-step-icon;
      height: $size-step-icon;

      @media screen and (max-width: $breakpoint-desktop) {
        padding-right: $margin-smaller;
        flex-basis: $size-step-icon-mobile;
        width: $size-step-icon-mobile;
        height: $size-step-icon-mobile;
      }
    }
  }
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  margin: $margin-quarter (-$margin-half) $margin-bigger;

  .select-with-label {
    display: flex;
    align-items: center;
    margin: 0 $margin-half;

    .select-text {
      padding-right: $margin-half;
    }

    .segment-select {
      min-width: 230px;
    }

    .phase-select {
      min-width: 155px;
    }

    .kind-select {
      min-width: 190px;
    }

    .status-select {
      min-width: 170px;
    }
  }

  @media screen and (max-width: $breakpoint-desktop) {
    margin: $margin-mini (-$margin-quarter) $margin-half;

    .select-with-label {
      margin: 0 $margin-quarter;
    }
  }
}

@media print {
  .app {
    display: block !important;
  }

  .navigation {
    display: none !important;
  }
}
