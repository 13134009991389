.next .target-groups {
  .editor-grid {
    th:first-child {
      width: 50%;
    }
  }

  .checkbox {
    display: inline-block;
  }
}
