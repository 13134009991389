@import './variables';

main.next {
  min-width: 960px;

  * {
    box-sizing: border-box;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
  }

  ul {
    list-style: none;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  .debug {
    display: none;
  }
}
