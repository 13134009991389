@import '../../styles/colors';
@import '../../styles/variables';
@import '../../../retailer/next/styles/variables.scss';
@import '../../styles/editor';

.template {
  flex: 1 1 auto;
  .editor-header {
    position: relative;
    z-index: 0 !important;

    &__actions {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }

  .drawer {
    .actions {
      height: 40px;
      display: flex;
      justify-items: center;
    }
    .drawer-close {
      display: none;
    }
  }
}

.add-content-template,
.add-content-block,
.add-content-field {
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  margin-bottom: 16px;
  font-size: 1rem;

  span,
  img {
    display: inline-block;
  }
  img {
    margin-left: 16px;
  }
}

.page-cover {
  position: fixed;
  bottom: 0;
  width: 1px;
  height: 1px;
  background-color: transparent;
  transition: background-color 0.1s;
  overflow: hidden;

  &.is-visible {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.2);
    transition: background-color 0.1s;
  }
}
