@import '../../../styles/variables';
@import '../../../styles/colors';

.next .content-templates {
  .pricing-item {
    padding: $sidebar-page-horizontal-padding 0;

    .pricing-component + .pricing-component {
      margin-top: $sidebar-page-horizontal-padding;
    }

    font-size: 12px;
    .pricing-steps {
      table {
        th {
          background-color: $k-ui-grey-10;
          padding: $sidebar-page-horizontal-padding * 0.5;
        }
        td {
          padding: 0 $sidebar-page-horizontal-padding * 0.5;
        }
      }
    }
  }
}
