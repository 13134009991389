@import '../../styles/variables.scss';

.next-retailer .modal {
  display: flex;
  justify-content: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .modal-content {
    position: relative;
    background: white;
    width: 800px;
    max-width: 100%;
    padding: 40px;
    overflow: auto;
    @media screen and (max-width: $breakpoint-desktop) {
      box-sizing: border-box;
      width: 100%;
      left: 0;
    }
  }
  .modal-close {
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
