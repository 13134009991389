@import '../../styles/variables';
@import '../../styles/colors';

.next .content-manager {
  padding: 16px 24px;
  flex: 1;
  max-width: $app-content-max-width;
  margin: 0 auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h2 {
      margin-bottom: 10px;
    }

    .add-button {
      margin-bottom: 0;
    }

    .header-buttons {
      display: flex;
      align-items: center;
    }
  }

  .save-button {
    border: 2px solid $k-ui-orange;
    color: $k-ui-orange;
    border-radius: $border-radius-normal;
    background-color: white;
    text-transform: uppercase;
    font-family: 'SkattaSans-Bold', sans-serif;
    display: inline-block;
    margin: 0 12px 0 24px;
    padding: 4px 12px;
    cursor: pointer;
  }

  .content-item-editor {
    .title-row__buttons {
      display: flex;
      align-items: center;
    }

    .unsaved-warning {
      font-size: 15px;
      color: $k-ui-orange;
      margin-right: 16px;
      margin: 0 16px 8px 0;
    }

    .content-item-fields {
      display: none;
    }

    &.expanded {
      .content-item-fields {
        display: block;
      }
    }
  }

  .entity-details {
    margin: 0px 0 16px 0;
    span {
      margin: 0 8px 0 0;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    color: $k-ui-orange;

    span {
      font-size: 13px;
    }

    svg {
      width: 36px;
      height: 36px;
      margin-left: 8px;
    }
  }

  .form-control {
    position: relative;
    padding-top: 16px;
  }

  .help-button {
    position: absolute;
    right: 0;
    top: 0;
  }
}
