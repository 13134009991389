@import '../../../../styles/variables.scss';
@import '../../../../../../next/styles/colors.scss';

.next-retailer .target-group-form {
  h3 {
    font-family: $font-default;
    margin-bottom: $margin-default;
    font-size: $font-size-xl;
  }
  h4 {
    margin-bottom: $margin-half;
  }

  .section-header {
    position: relative;
    .info-popover {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .form-dimensions {
    display: flex;
    flex-wrap: wrap;
  }
  .form-section {
    margin-bottom: $margin-default;
    min-width: 50%;
    @media screen and (max-width: $breakpoint-desktop) {
      width: 100%;
    }
  }

  .additional-dimensions {
    width: 100%;
  }

  .main-dimension {
    .dimension-options {
      display: flex;
      > * {
        min-width: 50%;
      }
      .checkbox {
        width: 100%;
        padding-bottom: 2px;
      }
    }
  }

  .main-dimension + .additional-dimensions {
    margin-top: $margin-default;
    padding-top: $margin-default;
    border-top: 1px solid $color-light-grey;
  }

  .dimension-section {
    width: 100%;
    header {
      position: relative;
      background: $color-lightest-grey;
      padding: 8px;
      margin-bottom: $margin-half;
      .dimension-title {
        margin: 0;
        text-transform: uppercase;
        font-size: $font-size-s;
      }
      .info-popover {
        position: absolute;
        right: 0;
        top: 0.25em;
        svg {
          width: 1.25em;
          height: 1.25em;
        }
      }
    }
  }
  .checkboxes {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;
    margin-bottom: $margin-half;
    .checkbox {
      cursor: pointer;
      box-sizing: border-box;
      min-width: 33%;
      padding: 5px 10px;
      &.checkbox-block {
        width: 100%;
      }
    }
  }

  .email-table-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    table.styled {
      table-layout: fixed;
      width: 100%;
      white-space: nowrap;
      border-collapse: collapse;

      td {
        line-height: 36px;
        border-right: 1px solid #f7f8f8;
        border-bottom: 1px solid #f7f8f8;
      }

      td > *,
      button > * {
        vertical-align: middle;
      }

      thead {
        text-align: left;
        font-weight: 600;

        th {
          display: flex;
          align-items: center;
        }
      }
    }

    .email-table-body {
      display: block;
      max-height: 20rem;
      min-height: 20rem;
      overflow-y: scroll;
      font-size: 0.85em;

      &.additional {
        min-height: 0;
      }

      .additional-email {
        width: 100%;
      }

      tr:nth-child(odd) {
        background-color: $k-ui-grey-02;
      }
      tr:hover {
        background-color: darken($k-ui-grey-02, 5%);
      }

      tr.email-group-row {
        background-color: #fff;
        color: $k-ui-grey-50;
      }
    }

    button {
      border: none;
      cursor: pointer;
      background-color: transparent;
      font-size: 1em;
    }

    .move-emails-button {
      font-weight: bold;
      font-size: $font-size-l;
      svg {
        height: 2em;
        width: 2em;
        position: relative;
      }

      &.is-selected {
        color: $k-ui-orange;
      }
    }
    .expand-email-group > svg {
      height: 24px;
      width: 24px;
      font-size: inherit;
    }
  }

  .prospect-header {
    display: flex;
    justify-content: flex-end;
    .info-popover {
      right: 0;
      top: 0.25em;
      svg {
        width: 1.25em;
        height: 1.25em;
      }
    }
  }
  .email-input-container {
    display: flex;
    align-items: center;
    .input-text {
      flex-grow: 1;
      margin-right: $margin-half;
    }
  }

  .additional-email-actions {
    background: $color-light-grey;
    width: 100%;
    padding: $margin-half;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .table-header {
    display: flex;
    flex-direction: row;
  }

  .grouped-email-pill {
    display: inline-block;
    color: white;
    background-color: $k-ui-orange;
    border-radius: 12px;
    width: 26px;
    line-height: 24px;
    margin-left: 10px;
    text-align: center;
  }

  .target-group-form-footer {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    .results {
      padding-top: 5px;
      font-size: $font-size-d;
    }
  }
}
