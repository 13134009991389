@import '../../../retailer/next/styles/variables.scss';
@import '../form/form.scss';

.next-retailer .offer-editor,
.next .offer-editor {
  .title-row {
    display: flex;
    justify-content: space-between;
  }

  .language-selector {
    display: inline-block;
    margin-top: 0;
  }

  h3 {
    font-family: $font-default;
    margin-bottom: $margin-default;
    display: inline-block;
  }

  .offer-image {
    max-width: 300px;
    max-height: 260px;
    @media screen and (max-width: $breakpoint-desktop) {
      max-width: 100%;
    }
    img {
      max-height: 210px;
    }
    .upload-prompt {
      padding: 75px 80px;
    }
  }

  .offer-types {
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin-default;
    .radio-pill {
      width: 100%;
    }
    .radio-pill + .radio-pill {
      margin-left: 5px;
    }
  }

  .offer-form-basket {
    .offer-image {
      &.loading {
        width: 262px;
        height: 250px;
        display: flex;
      }
    }
  }

  .input-group {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0 (-$margin-quarter);
    > .form-control {
      flex-grow: 1;
      margin: $margin-half $margin-quarter;
    }
    > label + .form-control {
      margin-top: 0;
    }
  }

  .limitation-notice {
    display: inline-block;
    margin-bottom: 6px;
    color: $color-k-orange;
  }

  .popover-pos-wrapper {
    button {
      border: none;
    }

    svg {
      width: 32px;
      height: 32px;
      margin-top: -2px;
    }
  }

  .popover {
    footer {
      a {
        display: flex;
      }
    }
  }
}
